import eventService from "@services/event.service";
import { queryOptions } from "@tanstack/react-query";

const eventsQueryOptions = () => {
  const { getEventsPublic, getEventByURL } = eventService();

  const optionsFetchEventsPublic = () => {
    return queryOptions({
      queryKey: ["eventsPublic"],
      queryFn: getEventsPublic,
    });
  };

  const optionsFetchEventPagePublic = (url: string) => {
    return queryOptions({
      queryKey: ["eventPageInfo", url],
      queryFn: () => getEventByURL(url),
    });
  };

  return {
    optionsFetchEventsPublic,
    optionsFetchEventPagePublic
  };
};

export const { optionsFetchEventsPublic, optionsFetchEventPagePublic } = eventsQueryOptions();
