import * as S from "./styles.ts";
import {
  Button,
  FormCreateTeam,
  NavigationButton,
  Pill,
  SelectedPlayers,
  Step,
  Title,
} from "./styles.ts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationTeamSchema } from "@validations/teams.schema.ts";
import useTeam from "@hooks/useTeam.ts";
import { CircleChevronLeft, CircleChevronRight, X } from "lucide-react";
import PlayerSelect from "@components/playerSelect";
import React, { useState } from "react";
import useGamePublic from "@hooks/useGamePublic.ts";
import { Select, Spinner } from "@chakra-ui/react";
import { toast } from "react-toastify";
import { TeamMembersRoles } from "@interfaces/team.interface.ts";
import { RemoveIcon } from "@components/playerSelect/styles.ts";

const CreateTeamModal = () => {
  const { createTeam, sendInvitations } = useTeam();
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const { gamesFilter } = useGamePublic();

  const handleStepChange = async () => {
    if (step === 1) {
      const isValid = await trigger();
      if (!isValid) {
        toast.error("Preencha todos os campos obrigatórios antes de avançar.");
        return;
      }
    }
    setStep(step === 1 ? 2 : 1);
  };

  const handleSelectedPlayersChange = (players) => {
    setSelectedPlayers(players);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    trigger,
  } = useForm({
    resolver: yupResolver(RegistrationTeamSchema),
  });

  const createTeamAndInvite: SubmitHandler<ICreateTeamRequest> = async (
    values,
    event
  ) => {
    event?.preventDefault();
    setIsLoading(true);
    try {
      const result = await createTeam(values);

      if (selectedPlayers.length > 0) {
        sendInvitations(result.id, transformSelectedPlayers(selectedPlayers));
      }

      return result;
    } catch (error) {
      toast.error("Erro ao criar o time ou enviar convites. Tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  const transformSelectedPlayers = (selectedPlayers) => {
    return selectedPlayers.map((player) => ({
      userId: player.id,
      role: TeamMembersRoles.PLAYER,
    }));
  };

  const handleRemove = (player) => {
    const updatedPlayers = selectedPlayers.filter((p) => p.id !== player.id);
    setSelectedPlayers(updatedPlayers);
    handleSelectedPlayersChange(updatedPlayers);
  };

  return (
    <FormCreateTeam>
      {step === 1 && <Title>CRIAR NOVO TIME</Title>}
      {step === 2 && <Title>CONVIDAR JOGADORES</Title>}

      <S.FormContainer>
        <S.RegisterForm onSubmit={handleSubmit(createTeamAndInvite)}>
          {step === 1 && (
            <Step>
              <S.FormLabel htmlFor="teamName">Nome do time</S.FormLabel>
              <S.FormInput
                id="teamName"
                type="text"
                placeholder="Nome do seu time"
                {...register("name")}
              />
              {isSubmitted && errors.name && (
                <S.FormError>{errors.name?.message}</S.FormError>
              )}
              <S.FormLabel htmlFor="teamTag">Tag do time</S.FormLabel>
              <S.FormInput
                id="teamTag"
                type="text"
                placeholder="Tag do time"
                {...register("teamTag")}
              />
              {isSubmitted && errors.teamTag && (
                <S.FormError>{errors.teamTag?.message}</S.FormError>
              )}
              <S.FormLabel htmlFor="game_id">Selecione um Jogo</S.FormLabel>
              <Select
                sx={{
                  "--select-bg": "#2f3237",
                }}
                placeholder="Selecione um Jogo"
                {...register("game_id")}
              >
                {gamesFilter?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
              <S.FormLabel htmlFor="description">Descrição</S.FormLabel>
              <S.FormTextarea
                id="description"
                rows={3}
                cols={50}
                placeholder="Escreva uma breve descrição para o seu time"
                {...register("description")}
                maxLength={200}
              />
              {isSubmitted && errors.description && (
                <S.FormError>{errors.description?.message}</S.FormError>
              )}
            </Step>
          )}
          {step === 2 && (
            <Step style={{ height: "300px" }}>
              <PlayerSelect
                selectedPlayers={selectedPlayers}
                onSelectedPlayersChange={handleSelectedPlayersChange}
                showSelected={false}
              />

              <SelectedPlayers>
                {selectedPlayers.map((player) => (
                  <Pill key={player.id}>
                    {player.name}
                    <RemoveIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(player);
                      }}
                    >
                      <X size={15} />
                    </RemoveIcon>
                  </Pill>
                ))}
              </SelectedPlayers>
            </Step>
          )}
          <NavigationButton>
            {step === 1 && (
              <Button onClick={handleStepChange}>
                Avançar
                <CircleChevronRight />
              </Button>
            )}
            {step === 2 && (
              <Button onClick={handleStepChange}>
                <CircleChevronLeft />
                Voltar
              </Button>
            )}

            {step === 2 && (
              <Button type="submit">
                {isLoading ? (
                  <>
                    <Spinner size="sm" mr={2} />
                    Salvando...
                  </>
                ) : (
                  "Salvar"
                )}
              </Button>
            )}
          </NavigationButton>
        </S.RegisterForm>
      </S.FormContainer>
    </FormCreateTeam>
  );
};

export default CreateTeamModal;
