import styled from "styled-components";
import { device } from "@utils/index.ts";

export const Card = styled.div`
  display: flex;
  height: auto;
  justify-content: space-between;
  overflow: hidden;
  background-color: ${({ bgColor }) =>
    bgColor || "var(--card-background-color)"};
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  border-radius: 8px;
  position: relative;
  width: 100%;

  @media ${device.tabletSmall} {
    flex-direction: column;
    height: fit-content;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    ${({ reverse, secondary }) =>
      reverse
        ? `
          border-width: 40px 0 0px 40px;
           border-color: ${secondary || "var(--card-background-color)"} transparent transparent transparent;
          top:0px;
          right: 0px;
        `
        : `
          border-width: 0 40px 40px 0;
          border-color: transparent transparent  ${secondary || "var(--card-background-color)"} transparent;
          bottom: 0;
          left: 0;
        `}
  }
`;

export const Header = styled.div`
  width: 50%;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;

  @media ${device.tabletSmall} {
    width: 100%;
    height: 250px;
  }

  @media ${device.laptop} {
    width: 100%;
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  color: ${({ textColor }) => textColor || "#FFF"};

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.tabletSmall} {
    padding: 2rem;
  }

  @media ${device.laptop} {
    font-size: 1rem;
  }
`;

export const Title = styled.h3`
  padding: 10px;
  font-weight: bold;
  font-size: 2rem;
  color: ${({ textColor }) => textColor || "#FFF"};

  @media ${device.tablet} {
    font-size: 1.4rem;
  }
`;
export const Details = styled.div``;
