import React, { ChangeEvent, useState } from "react";
import {
  Arrow,
  Avatar,
  Container,
  Dropdown,
  DropdownItem,
  Input,
  PlayerInfo,
  PlayerName,
  SearchInput,
  SelectButton,
} from "@components/playerSelect/styles.ts";
import { ChevronDown, ChevronUp, CircleUserRound, Search } from "lucide-react";
import useUserPublic from "@hooks/useUserPublic.ts";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";
import useUser from "@hooks/useUser.ts";

const PlayerSelected = ({
  selectedPlayers,
  showSelected,
  onSelectedPlayersChange,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const { searchUsersPaginationSelect } = useUserPublic();
  const { userAuth } = useUser();

  const [currentPage, setCurrentPage] = useState(1);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleSelect = (player) => {
    const idUser = userAuth?.id;

    if (player.id === idUser) {
      return;
    }

    const isAlreadySelected = selectedPlayers.some((p) => p.id === player.id);
    const updatedPlayers = isAlreadySelected
      ? selectedPlayers.filter((p) => p.id !== player.id)
      : [...selectedPlayers, player];

    onSelectedPlayersChange(updatedPlayers);
  };

  const handleRemove = (player) => {
    const updatedPlayers = selectedPlayers.filter((p) => p.id !== player.id);

    onSelectedPlayersChange(updatedPlayers);
  };

  const handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setInputValue(event.target.value);
  };

  const searchParams: ISearchPagination = {
    order: "asc",
    limit: 70000,
    search: inputValue,
    page: currentPage,
  };

  const { players } = searchUsersPaginationSelect(searchParams);

  // exclui o usuario logado
  const filteredPlayers = players.filter(
    (player) => player.id !== userAuth?.id
  );

  return (
    <Container>
      <SelectButton onClick={toggleDropdown}>
        <p>{selectedPlayers.length} jogadores selecionados</p>)
        <Arrow>{isOpen ? <ChevronUp /> : <ChevronDown />}</Arrow>
      </SelectButton>

      {isOpen && (
        <Dropdown>
          <SearchInput>
            <Input
              value={inputValue}
              onChange={handleInputChange}
              type="text"
              id="search-input-user-select"
              placeholder="Buscar por nome/nick"
            />
            <Search />
          </SearchInput>

          {filteredPlayers.map((player) => (
            <DropdownItem
              key={player.id}
              isSelected={selectedPlayers.some((p) => p.id === player.id)}
              onClick={() => handleSelect(player)}
            >
              <PlayerInfo>
                <PlayerName>{player.name}</PlayerName>

                {player.picture ? (
                  <Avatar src={player.picture} alt={player.name} />
                ) : (
                  <CircleUserRound size={30} />
                )}
              </PlayerInfo>
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default PlayerSelected;
