import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  SmallAvatarPlaceholder,
} from "@components/avatarProfile/styles.ts";
import { UserRound } from "lucide-react";
import Tooltip from "@components/tooltip";

const AvatarProfile = ({ avatar, url, name }) => {
  return (
    <Tooltip content={name}>
      <Link to={url}>
        {avatar ? (
          <Avatar src={avatar} alt="Avatar" />
        ) : (
          <SmallAvatarPlaceholder>
            <UserRound size="43" />
          </SmallAvatarPlaceholder>
        )}
      </Link>
    </Tooltip>
  );
};

export default AvatarProfile;
