import { progamersApi } from "@config/axios.config";
import { BaseResponse } from "@interfaces/base";
import { TeamUser } from "@interfaces/team.interface";
import {
  IRequestSetup,
  IUser,
  IUserEquipament,
  SearchUserResponse,
  UpdateUser,
} from "@interfaces/user.interface";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";

export default function userService() {
  const getMe = async (): Promise<BaseResponse<IUser>> => {
    const { data } = await progamersApi.get("/user/profile/me");
    return data as BaseResponse<IUser>;
  };

  const getUserByIdPublic = async (
    userId: string,
  ): Promise<BaseResponse<IUser>> => {
    const { data } = await progamersApi.get(`/public/user/${userId}`);
    return data as BaseResponse<IUser>;
  };

  const getSearchUser = async (
    name?: string,
  ): Promise<BaseResponse<SearchUserResponse>[]> => {
    const { data } = await progamersApi.get(
      `/public/user/options/all?name=${name}`,
    );
    return data;
  };

  const putProfile = async (userId: string, updateUser: UpdateUser) => {
    await progamersApi.put(`/user/${userId}`, {id:userId, ...updateUser});
  };

  const getTeamsUserById = async (
    id: string,
  ): Promise<BaseResponse<TeamUser>> => {
    const result = await progamersApi.get(`user/${id}/teams`);
    return result.data;
  };

  const postSetupUser = async (userId: string, data: IRequestSetup) => {
    await progamersApi.post(`user/${userId}/equipament`, data);
  };

  const putSetupUser = async (
    userId: string,
    equipamentId: string,
    data: IRequestSetup,
  ) => {
    await progamersApi.put(`user/${userId}/equipament/${equipamentId}`, data);
  };

  const getSetupByUserIdPublic = async (
    userId: string,
  ): Promise<BaseResponse<IUserEquipament>[]> => {
    const result = await progamersApi.get(`public/user/${userId}/equipament`);
    return result.data;
  };

  const getUsersSearchPagination = async (searchPagination:ISearchPagination
  ): Promise<BaseResponse<IUserEquipament>[]> => {

    const query = Object.entries(searchPagination)
      .filter(([key, value]) => value != null)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    return await progamersApi.get(`public/user/options/search/?${query}`);
  };

  return {
    getMe,
    getUserByIdPublic,
    getSearchUser,
    putProfile,
    getTeamsUserById,
    postSetupUser,
    putSetupUser,
    getSetupByUserIdPublic,
    getUsersSearchPagination
  };
}
