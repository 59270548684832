import userService from "@services/user.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { UserContext } from "@context/UserContext";
import { UpdateUser } from "@interfaces/user.interface";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { QUERY_KEY_USER } from "../constants/queryKeys.constants";
import { FileBussinessType } from "@interfaces/file.interface";
import { optionsFetchSearchUser } from "@plugins/reactQuery/user.query-options";
import { extractNumbers } from "@utils/extractNumbers";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserUpdateSchema } from "@validations/user.schema";

const useUser = () => {
  const {
    user: userAuth,
    files: filesAuth,
    isOpenUserProfileFinishingModal,
    onCloseUserProfileFinishingModal,
    onOpenUserProfileFinishingModal,
  } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { putProfile } = userService();
  const fullProfileItems = [userAuth?.cpf, userAuth?.nick, userAuth?.phone];

  const avatarFile = filesAuth.find(
    (item) => item.business_type === FileBussinessType.USER_PROFILE_PHOTO
  );

  const searchUser = (name: string) => {
    const { data } = useQuery(optionsFetchSearchUser(name));

    return data;
  };

  const submitUpdateUserProfile: SubmitHandler<UpdateUser> = async (
    values,
    event
  ) => {
    try {
      event?.preventDefault();
      const { cpf, ...rest } = values;
      await putProfile(userAuth?.id!, {
        ...rest,
        phone: extractNumbers(values.phone),
        cpf: extractNumbers(cpf),
      });
      toast.success("Atualizado com Sucesso!");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER.PROFILE] });
      onCloseUserProfileFinishingModal();
    } catch (error) {
      toast.error("Tivemos um Erro.");
    }
  };

  useEffect(() => {
    if (!userAuth) return;
    if (fullProfileItems.some((item) => item === null || item === "")) {
      onOpenUserProfileFinishingModal();
    }
  }, [userAuth]);

  const {
    register: formRegisterProfile,
    handleSubmit: handleSubmitProfile,
    reset: resetFormProfile,
    formState: { errors, isSubmitted, isValid },
  } = useForm<UpdateUser>({
    resolver: yupResolver(UserUpdateSchema),
    values: {
      name: userAuth?.name!,
      nick: userAuth?.nick || "",
      gender: userAuth?.gender!,
      phone: userAuth?.phone || "",
      cpf: userAuth?.cpf || "",
    },
  });

  return {
    userAuth,
    filesAuth,
    teams: userAuth?.teams || [],
    searchUser,
    submitUpdateUserProfile,
    avatarFile,
    isOpenUserProfileFinishingModal,
    onCloseUserProfileFinishingModal,
    formUpdateProfileUser: {
      handleSubmitProfileUser: handleSubmitProfile(submitUpdateUserProfile),
      formRegisterProfileUser: formRegisterProfile,
      resetFormProfile,
      isSubmitted,
      isValid,
      errors,
    },
    setup: {},
  };
};
export default useUser;
