import { optionsFetchGamesPublic } from "@plugins/reactQuery/queryOptions.reactquery";
import { useQuery } from "@tanstack/react-query";
import { IGame, IGameFilter } from "@interfaces/game.interface.ts";
import { BaseResponse, PaginationResponse } from "@interfaces/base.ts";

const useGamePublic = () => {
  const { data: listGames } = useQuery(optionsFetchGamesPublic());

  (listGames);

  function mapToGameFilter(
    data: PaginationResponse<BaseResponse<IGame>>
  ): IGameFilter[] {
    return data.data.map((item) => {
      const { id, name } = item.entity;

      const iconFile = item.files.find((file: any) => file.business_type === "GAMES_ICON");
      const icon = iconFile ? iconFile.url : null;

      return {
        id,
        name,
        icon,
        selected: false,
      };
    });
  }

  const gamesFilter: IGameFilter[] = listGames ? mapToGameFilter(listGames) : [];

  return { gamesFilter };
};

export default useGamePublic;
