import React from "react";
import { Link } from "react-router-dom";
import { Swords, UsersRound } from "lucide-react";
import {
  Avatar,
  AvatarsContainer,
  AvatarTeamPlaceholder,
  Body,
  Card,
  Header,
  TeamMembersCount,
  Title,
} from "@components/teamCard/styles.ts";
import AvatarProfile from "@components/avatarProfile/AvatarProfile.tsx";

// Card Component
const TeamCard = ({
  backgroundImage,
  avatar,
  title,
  avatars,
  team_members_count,
  id,
}) => {
  return (
    <Card>
      <Link to={`/team/${id}`}>
        <Header
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        >
          <TeamMembersCount>
            <UsersRound size="19" /> {team_members_count}
          </TeamMembersCount>

          {avatar ? (
            <Avatar src={avatar} alt="Avatar" />
          ) : (
            <AvatarTeamPlaceholder>
              <Swords size="45" />
            </AvatarTeamPlaceholder>
          )}
        </Header>
      </Link>
      <Body>
        <Title>{title}</Title>
        {avatars.length > 0 && (
          <AvatarsContainer>
            {avatars.map((profile, index) => (
              <AvatarProfile
                name={profile.name}
                avatar={profile.avatar}
                key={profile.id}
                url={profile.url}
              />
            ))}
          </AvatarsContainer>
        )}
      </Body>
    </Card>
  );
};

export default TeamCard;
