import { TeamMembersRoles, TeamNotificationUserStatus } from "./team.interface";

export type Notifications = {
  id: string;
  title: string;
  type: NotificationsType;
  createAt: Date;
  isRead: boolean;
  body: string[] | INotificationInvite;
};

export interface INotificationInvite {
  id: string;
  team_id: string;
  status: TeamNotificationUserStatus;
  created_at: Date;
  updated_at: Date;
  role: TeamMembersRoles;
  team: {
    id: string;
    name: string;
    team_tag: string;
    profilePhoto: string;
  };
  user: IUserNotificationInvite;
  userWhoInvited: IUserNotificationInvite;
}

interface IUserNotificationInvite {
  id: string;
  nick: string;
  email: string;
}

export interface INotificationWebsocket {
  id: string;
  user_id: string;
  title: string;
  description: string;
  context_type: string;
  entity_id: string;
  type: string;
  link: string;
  icon: string;
  read: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export enum NotificationsType {
  TEAM_INVITE = "TEAM_INVITE",
  TEAM_JOIN = "TEAM_JOIN",
  TEAM_LEAVE = "TEAM_LEAVE",
  TEAM_KICK = "TEAM_KICK",
  TEAM_UPDATE = "TEAM_UPDATE",
  TEAM_DELETE = "TEAM_DELETE",
  CHAMPIONSHIP_INVITE = "CHAMPIONSHIP_INVITE",
  CHAMPIONSHIP_CANCEL = "CHAMPIONSHIP_CANCEL",
  CHAMPIONSHIP_IS_CLOSE = "CHAMPIONSHIP_IS_CLOSE",
  CHAMPIONSHIP_START = "CHAMPIONSHIP_START",
  CHAMPIONSHIP_REGISTER_ACCEPT = "CHAMPIONSHIP_REGISTER_ACCEPT",
  CHAMPIONSHIP_REGISTER_REJECT = "CHAMPIONSHIP_REGISTER_REJECT",
  CHAMPIONSHIP_RESULT = "CHAMPIONSHIP_RESULT",
  CHAMPIONSHIP_MATCH_RESULT = "CHAMPIONSHIP_MATCH_RESULT",
  CHAMPIONSHIP_MATCH_CANCEL = "CHAMPIONSHIP_MATCH_CANCEL",
  CHAMPIONSHIP_MATCH_RESCHEDULE = "CHAMPIONSHIP_MATCH_RESCHEDULE",
  CHAMPIONSHIP_MATCH_UPDATE = "CHAMPIONSHIP_MATCH_UPDATE",
  CHAMPIONSHIP_MATCH_CODE = "CHAMPIONSHIP_MATCH_CODE",
  CHAMPIONSHIP_MATCH_IS_CLOSE = "CHAMPIONSHIP_MATCH_IS_CLOSE",
  CHAMPIONSHIP_MATCH_IS_CLOSE_TIME_15 = "CHAMPIONSHIP_MATCH_IS_CLOSE_TIME_15",
  USER_ALTER_PASSWORD = "USER_ALTER_PASSWORD",
  BADGET_RECEIVE = "BADGET_RECEIVE",
  EVENT_ANNOUNCE = "EVENT_ANNOUNCE",
  ANNOUNCE_NEW = "ANNOUNCE_NEW",
  REFUSED = "REFUSED",
}
