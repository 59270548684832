import styled from "styled-components";
import { device } from "@utils/index";
import { Link } from "react-router-dom";

export const Checkbox = styled.input.attrs({ type: "checkbox" })`
    width: 20px;
    height: 20px;
    appearance: none;
    background: var(--white);
    cursor: pointer;
    outline: none;
    border-radius: 3px;
    &:checked { 
        background-color: var(--layout-color);
    }

    &:checked + label {
        color: var(--layout-color); 
        font-weight: bold;
    }
    
`;

export const CheckboxLabel = styled.label`
  font-size: 1.3rem;
  margin-left: 0.5rem;
  font-weight: normal;
  @media ${device.tablet} {
    font-size: 12px;
  }

  @media ${device.mobileLarge} {
    font-size: 12px;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  margin-top: 20px; 
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  @media ${device.tablet} {
    flex-direction: column;
  }
 
`;

export const SearchBox = styled.div`
  background-color: #0f1b34;
  margin-top: 20px;

    border-radius: 5px ;
  padding: 20px;
  flex: 1;
`;

export const Label = styled.label`
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 1.5rem;

  @media ${device.mobileLarge} {
    font-size: 15px;
  }
`;

export const SearchInput = styled.div`
  display: flex;

    border-radius: 5px;
  align-items: center;
  background-color: var(--input-filter-color);
  margin-bottom: 1rem;
  padding: 10px;
  width: fit-content;

  @media ${device.tablet} {
    font-size: 12px;
  }

  @media ${device.mobileLarge} {
    font-size: 12px;
    width: 180px;
  }
`;

export const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  color: white;
    border-radius: 5px;

  @media ${device.tablet} {
    font-size: 12px;
    width: 180px;
  }

  @media ${device.mobileLarge} {
    font-size: 12px;
  }
`;

export const BlurOverlay = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    gap: 20px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    z-index: 1;

    @media ${device.notebook} {
        font-size: 14px;
    }
`;

export const ActionBox = styled.div`
    background-color: var(--card-body-background-color);

    margin-top: 20px;
    padding: 20px;
    position: relative;
    border-radius: 5px;
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.tablet} {
        width: 100%;
    }

    @media ${device.mobileLarge} {
        width: 100%;
    }
`;

export const AlertMessage = styled.div`
  display: ${(props) => (!props.isLoggedIn ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 200px;
  gap: 20px;
    width: 100%;
  left: 74%;
  z-index: 100;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 16px;
  padding: 20px;
`;

export const LoginButton = styled(Link)`
  background-color: var(--layout-color);
  color: var(--white);
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: var(--button-hover-color);
  }
`;
