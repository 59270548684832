import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10rem;
  color: #fc3a3a;
  font-family: "Press Start 2P", cursive;
  text-align: center;
`;
const Title = styled.h1`
  font-size: 10rem;
  text-shadow: 2px 2px 10px #fc3a3a;
`;
const Message = styled.p`
  font-size: 3rem;
  margin-top: 20px;
`;
const Button = styled.button`
  padding: 10px 20px;
  border-radius: 8px;
  margin-top: 3rem;
  font-size: 1rem;
  background-color: #fc3a3a;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  text-transform: uppercase;
  &:hover {
    background-color: #f82020;
    transform: scale(1.1);
  }
`;

const EventNotFound: React.FC = () => {
  return (
    <Container>
      <Title>404</Title>
      <Message>Game Over! </Message>
      <Message> Página não encontrada.</Message>
      <Button onClick={() => (window.location.href = "/")}>
        Voltar ao Início
      </Button>
    </Container>
  );
};
export default EventNotFound;
