import styled from "styled-components";

export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0.1rem solid transparent; 
    &:hover {
        border-color: var(--layout-color);
    }
`;

export const SmallAvatarPlaceholder = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--card-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid transparent; 
    padding: 0.3rem;
    &:hover {
        border-color: var(--layout-color);
    }
`;
