import Layout from "@components/template/layout";
import {
  Announcement,
  AnnouncementBody,
  AnnouncementSubtitle,
  AnnouncementTitle,
  FormContainer,
  Hastag,
  LogoProGamers,
  ProGamers,
  Slung,
  TabButton,
  Title,
} from "./styles";
import bannerLCG from "@assets/ativos/png/bannerLCG.png";
import logoHorizontal from "@assets/ativos/png/logohorizontal.png";

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import PlayersFilter from "@pages/social/filter/players";
import TeamFilter from "@pages/social/filter/team";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

const SocialPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
  <>
      <Helmet>
        <title>Social - Progamers</title>
        <meta property="og:title" content="Social - Progamers" />
      </Helmet> 
    <Layout
          styleContainer={{ overflow: "visible" }}
          styleBodyContainer={{ overflow: "visible" }}
        >
          <ProGamers
            style={{
              backgroundColor: selectedTab === 0 ? "#e94560" : "transparent",
              backgroundImage: selectedTab === 0 ? "none" : `url(${bannerLCG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {selectedTab === 0 ? (
              <LogoProGamers src={logoHorizontal} />
            ) : (
              <Slung>Faça Parte da maior comunidade de games brasileira</Slung>
            )}

            <Hastag>#VEMSERPRO</Hastag>
          </ProGamers>
          <FormContainer>
            <Title>Está buscando</Title>
            <Tabs variant="unstyled" onChange={(index) => setSelectedTab(index)}>
              {/* Lista de Abas */}
              <TabList>
                {["Jogadores", "Times"].map((tab, index) => (
                  <Tab
                    bgColor={"#e94560"}
                    color={"#ffffff"}
                    borderRadius={"5px"}
                    _selected={{ borderBottom: "4px solid #ffffff" }}
                    flex={{ base: "1", lg: "none" }}
                    w={{ base: "auto", lg: "160px" }}
                    key={index}
                    as={TabButton}
                  >
                    {tab}
                  </Tab>
                ))}
              </TabList>

              <TabPanels >
                <TabPanel width={"100%"} margin={"0px"} padding={"0px"}>
                  <PlayersFilter />
                </TabPanel>
                <TabPanel>
                  <TeamFilter />
                </TabPanel>
              </TabPanels>
            </Tabs>

            {selectedTab > 0 && (
              <Announcement>
                <AnnouncementTitle>Em breve</AnnouncementTitle>
                <div>
                  <AnnouncementBody style={{ backgroundColor: "#e94560" }}>
                    <AnnouncementSubtitle>IN-HOUSE</AnnouncementSubtitle>
                    <LogoProGamers src={logoHorizontal} />
                    <Hastag>#VEMSERPRO</Hastag>
                  </AnnouncementBody>
                </div>
              </Announcement>
            )}
          </FormContainer>
        </Layout>
  </>

  );
};

export default SocialPage;
