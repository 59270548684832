import { useEffect, useState } from "react";
import {
  ActionButton,
  Body,
  Card,
  DescriptionText,
  EventInfo,
  Header,
  StatusEvent,
  Title,
} from "@components/EventCardView/styled.ts";
import { Link } from "react-router-dom";
import { Calendar, MapPinned } from "lucide-react";
import { formatDates } from "@utils/date.ts";

const EventCardView = ({ backgroundImage, url, event }) => {
  const eventAddress = url.split("/");
  const category = eventAddress[0];
  const eventName = eventAddress[1];

  const startDate = formatDates(event?.start_date, event?.end_date);
  const status = event?.start_date > new Date().toISOString() ? "EM BREVE" : new Date().toISOString() 
  <= event?.end_date && event?.start_date <= new Date().toISOString() ? "EM ANDAMENTO" : "ENCERRADO";

  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = () => {
      setSize({ width: img.width, height: img.height });
    };
  }, [backgroundImage]);

  return (
    <Card>
      {backgroundImage && (
        <Header
          backgroundImage={backgroundImage}
        />
      )}

      <Body>
        <Title>{event?.name}</Title>
        <DescriptionText>
          <div
            className={"decription-html"}
            style={{ marginLeft: "10px" }}
            dangerouslySetInnerHTML={{ __html: event?.description }}
          ></div>

          <EventInfo>
            <div className={"info-box"}>
              <Calendar /> {startDate}
            </div>
            <div className={"info-box"}>
              <MapPinned />
              {event?.location}
            </div>
            <StatusEvent status={status}>
              {status}
            </StatusEvent>
          </EventInfo>

          <Link to={`/${category}/${eventName}`}>
            <ActionButton>
              <span>Saiba mais </span>
            </ActionButton>
          </Link>
        </DescriptionText>
      </Body>
    </Card>
  );
};

export default EventCardView;
