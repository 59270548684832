import Providers from "@components/template/Providers";
import Toast from "./components/toast";
import AppRoutes from "./routes";
import { GlobalStyle } from "./styles";
import { HelmetProvider } from "react-helmet-async";


export default function App() {
  return (
    <>
      <HelmetProvider>
        <GlobalStyle />
        <Providers>
          <Toast />
          <AppRoutes />
        </Providers>
      </HelmetProvider>

    </>
  );
}
