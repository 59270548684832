import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  background-color: var(--select-color);
  border: 1px solid #333;
  padding: 0.5rem;
  border-radius: 8px;
  color: white; 
`;

export const PlayerName = styled.span`
  font-size: 16px;
`;

export const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
 
  background-color: var(--dropdown-color);
  border-radius: 8px;
  margin-top: 8px;
  max-height: 280px; 
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  margin: 10px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #0a1428;
  opacity: ${(props) => (props.isSelected ? 0.5 : 1)};
  color: ${(props) => (props.isSelected ? "var(--text-greay)" : "var(--white)")};

  &:hover {
    background-color: ${(props) =>
      props.isSelected
        ? "var(--pill-background-color)"
        : "var(--layout-color)"};
  }
`;

export const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  cursor: pointer;
  flex-wrap: wrap;
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.2rem;
  margin-top: 0.3rem;
  background-color: var(--pill-background-color);
  padding-left: 0.5rem;
  font-size: 14px;
  color: var(--white);
`;

export const RemoveIcon = styled.span`
  margin-left: 8px;
  cursor: pointer;
  color: var(--layout-color);
  font-weight: bold;
`;

export const PlayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;

export const Arrow = styled.span`
  margin-left: auto;
`;


export const SearchInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    margin: 10px;
    cursor: pointer;
    border-radius: 8px;
    background-color: #0a1428;

    position: sticky;
    top: 0;

    z-index: 10;


`;

export const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  color: white; 
    width: 90%;
`;