import styled from "styled-components";
import { device, statusColors } from "@utils/index.ts";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px; 
    
    max-height: 564px;
    height: 100%;
   
    color: #ffffff;
    transition-duration: 1s;
    cursor: pointer;

    @media ${device.tabletSmall} {
        max-width: 350px;
    }


    &:hover {
        transition-duration: 0.8s;
        transform: scale(0.9);
    }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const CardChampionshipImage = styled.img`
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const CardGameImage = styled.img`
  position: absolute;
  width: 3.125rem;
  border-radius: 0.25rem;
  margin: 1rem;
  border: 0.125rem solid #fff;
`;

export const DetailsContainer = styled.div`
  font-size: var(--font-size-medium);
  position: absolute;
  bottom: 0;
  z-index: 996;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  background-color: rgba(31, 43, 68, 0.8);

  strong {
    font-size: var(--font-size-medium);
    text-align: center;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1f2b44;
  min-height: 164px;
  height: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const CardTitle = styled.p`
    height: 3.75rem;
    font-size: var(--font-size-large);
    padding: 1rem;


    @media ${device.tabletSmall} {
        font-size: 1rem;
    }
`;

export const CardFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardSmallInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const CardSmallInfoText = styled.span`
  font-size: var(--font-size-base);

    @media ${device.tabletSmall} {
        font-size: 0.8rem;
    }
`;


export const containerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface StatusProps {
  status: string;
}

export const StatusDiv = styled.div<StatusProps>`
  padding: 4px 8px 4px 8px;
  border: 2px solid ${({ status }) => statusColors[status].color};
  color: ${({ status }) => statusColors[status].color};
  font-weight: 600;
  text-align: center;
  font-size: small;
  border-radius: 8px;
  margin-bottom: 0.5rem;
`;