import * as S from "./styles";
import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import NotificationItem from "./NotificationItem";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import useNotification from "@hooks/useNotification";

type Props = {
  onMouseLeave: any;
};

const NotificationArea: React.FC<Props> = ({ onMouseLeave }) => {
  const { notifications } = useNotification();
  const { ref, inView } = useInView();

  const notificationList = notifications();

  useEffect(() => {
    if (inView) {
      notificationList.fetchNextPage();
    }
  }, [inView]);

  return (
    <S.DropdownContainerWrapper onMouseLeave={onMouseLeave}>
      <S.DropdownHeader>
        <Text color={"red.100"} fontSize={"18px"}>
          Notificações
        </Text>
      </S.DropdownHeader>
      <Flex h={"390px"} overflow={"auto"} flexDir={"column"}>
        {notificationList.data?.map((item) => (
          <NotificationItem notification={item} key={item.id} />
        ))}
        <Box
          ref={ref}
          h={"80px"}
          fontSize={"20px"}
          alignSelf={"center"}
          color={"#e94560"}
        >
          {notificationList.isFetchingNextPage && (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#E94560"
              size="lg"
            />
          )}
        </Box>
      </Flex>
    </S.DropdownContainerWrapper>
  );
};

export default NotificationArea;
