import { Link, useLocation } from "react-router-dom";
import { Home, UsersRound } from "lucide-react";

import Logo from "@assets/ativos/png/Logo.png";

import { ButtonSection, HeaderLogo, HeaderUl, LeftContainer } from "./styles";

export default function HeaderLeft() {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === path ? "active link" : "link";
  };

  return (
    <LeftContainer>
      <Link to="/">
        <HeaderLogo src={Logo} alt="GoGamers" />
      </Link>
      <HeaderUl>
        <Link to="/">
          <li className={isActive("/")}>
            <ButtonSection>
              <Home size="18" />
              <p>Início</p>
            </ButtonSection>
          </li>
        </Link>
        <Link to="/social">
          <li className={isActive("/social")}>
            <ButtonSection>
              <UsersRound size="18" />
              <p>Social</p>
            </ButtonSection>
          </li>
        </Link>
        {/* <Link to="/championships">
          <li className={isActive("/championships")}>
            <ButtonSection>
              <Trophy size="18" />
              <p>Campeonatos</p>
            </ButtonSection>
          </li>
        </Link> */}
      </HeaderUl>
    </LeftContainer>
  );
}
