import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Saira:wght@300;400;500;700&display=swap');

    @font-face {
        font-family: 'Quinn';
        src: local('Quinn'), url('../../../assets/fonts/quinn/Quinn-Bold.otf') format('otf');
    }
    @font-face {
        font-family: 'Serotiva';
        src: local('Serotiva'), url('../../../assets/fonts/serotiva/Serotiva-Light.otf') format('otf');
    }
    
    body, h1, h2, h3, h4, h5, h6, p, a, span, div, input, select, option, button, textarea, label {
        font-family: 'Poppins', sans-serif;

        .ml-10 {
            margin-left: 10px;
        }

        /* Para navegadores baseados em WebKit (Chrome, Edge, Safari) */
        ::-webkit-scrollbar {
            width: 6px; 
            height: 6px;  
        }

        ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 5px
             
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--layout-color);  
            padding: 5px; 
            border: 2px solid var(--layout-color); 
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: var(--button-hover-color);
        }

    }`;
