import { PaginationResponse } from "@interfaces/base";
import { Notifications } from "@interfaces/notification.interface";
import {
  optionsFetchAllNotificationUnred,
  optionsFetchInfinitNotifications,
} from "@plugins/reactQuery/notification.query-options";
import notificationService from "@services/notification.service";
import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import useTeam from "./useTeam";
import useAuth from "./useAuth";

const useNotification = () => {
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAuth();
  const { acceptionInvitation, declineInvitation } = useTeam();
  const { pathMarkAsRead, deleteNotificationById } = notificationService();

  const notifications = () => {
    const { data, ...result } = useInfiniteQuery(
      optionsFetchInfinitNotifications()
    );

    const notificationsList = data?.pages.flatMap((item) => item.data);

    return { data: notificationsList, ...result };
  };

  const markAsRead = async (notificationId: string) => {
    await markAsReadMutation.mutateAsync(notificationId);
  };

  const markAsReadMutation = useMutation({
    mutationFn: async (id: string) => {
      await pathMarkAsRead(id);
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["notificationAll"] });

      const previousData = queryClient.getQueryData<
        InfiniteData<PaginationResponse<Notifications>>
      >(["notificationAll"]);

      if (previousData) {
        queryClient.setQueryData(["notificationAll"], {
          ...previousData,
          pages: previousData.pages.map((page) => ({
            ...page,
            data: page.data.map((notification) =>
              notification.id === id
                ? { ...notification, isRead: true }
                : notification
            ),
          })),
        });
      }

      return { previousData };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(["notificationAll"], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        optionsFetchAllNotificationUnred(isAuthenticated)
      );
    },
  });

  const deleteNotification = async (id: string) => {
    await deleteMutation.mutateAsync(id);
  };

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      await deleteNotificationById(id);
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: ["notificationAll"] });
      await queryClient.invalidateQueries(
        optionsFetchAllNotificationUnred(isAuthenticated)
      );

      const previousData = queryClient.getQueryData<
        InfiniteData<PaginationResponse<Notifications>>
      >(["notificationAll"]);

      queryClient.setQueryData(
        ["notificationAll"],
        (oldData: InfiniteData<PaginationResponse<Notifications>>) => {
          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.filter((notification) => notification.id !== id),
            })),
          };
        }
      );

      return { previousData };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(["notificationAll"], context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        optionsFetchAllNotificationUnred(isAuthenticated)
      );
    },
  });

  const allUnreadNotifications = useQuery(
    optionsFetchAllNotificationUnred(isAuthenticated)
  );

  const numberOpenNotifications = allUnreadNotifications?.data?.length || 0;

  const newNotificationSocket = async () => {
    await queryClient.invalidateQueries(
      optionsFetchAllNotificationUnred(isAuthenticated)
    );
    await queryClient.fetchInfiniteQuery(optionsFetchInfinitNotifications());
    toast.info("Nova Notificação");
  };

  const acceptionInvitationNotification = async (
    teamId: string,
    inviteId: string
  ) => {
    await acceptionInvitation(teamId, inviteId);
    await queryClient.fetchInfiniteQuery(optionsFetchInfinitNotifications());
  };
  const declineInvitationNotification = async (
    teamId: string,
    inviteId: string
  ) => {
    await declineInvitation(teamId, inviteId);
    await queryClient.fetchInfiniteQuery(optionsFetchInfinitNotifications());
  };

  return {
    notifications,
    markAsRead,
    deleteNotification,
    numberOpenNotifications,
    newNotificationSocket,
    declineInvitationNotification,
    acceptionInvitationNotification,
  };
};

export default useNotification;
