import React, { ReactNode, useState } from "react";
import { TooltipContainer, TooltipText } from "@components/tooltip/styles.ts";

interface TooltipProps {
  content: string;
  children: ReactNode;
}

const Tooltip = ({ content, children }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TooltipContainer
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <TooltipText isVisible={isVisible}>{content}</TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;
