import { Flex, Img, Text } from "@chakra-ui/react";
import MedalFirstPlace from "@assets/icons/MedalFirstPlace.svg";
import MedalSecondPlace from "@assets/icons/MedalSecondPlace.svg";
import BronzeMedal from "@assets/icons/BronzeMedal.svg";
import DefaultMedal from "@assets/icons/DefaultMedal.svg";
import { BaseResponse } from "@interfaces/base";
import { ChampionshipAward } from "@interfaces/championships.interface";
import { FileBussinessType } from "@interfaces/file.interface";
import { convertValueToMoney } from "@utils/money";

type Props = { awards: BaseResponse<ChampionshipAward>[] | number[] };
type PositionData = { label: string; pathIcon: string };

const Awards: React.FC<Props> = ({ awards }) => {
  const positionAward = (position: number): PositionData => {
    return (
      {
        1: { label: "ST", pathIcon: MedalFirstPlace },
        2: { label: "ND", pathIcon: MedalSecondPlace },
        3: { label: "RD", pathIcon: BronzeMedal },
      }[position] || {
        label: "TH",
        pathIcon: DefaultMedal,
      }
    );
  };

  return (
    <Flex flexDirection="column" width="100%">
      {awards?.map((award: BaseResponse<ChampionshipAward> | number) => {
        const isNumber = typeof award === "number";
        const awardPosition = isNumber ? award : award.entity.position;
        const positionData = positionAward(isNumber ? award : awardPosition);
        const file =
          !isNumber &&
          award.files?.find(
            (item) => item.business_type === FileBussinessType.AWARD_ICON
          );

        return (
          <Flex
            key={isNumber ? award : award.entity?.id}
            bg="#162230"
            padding="2px"
            marginBottom="4"
            minWidth="150px"
            h={"auto"}
          >
            <Flex
              width="100%"
              height="74px"
              bg="#0C1A27"
              flexDirection="row"
              h={"auto"}
            >
              <Flex flex="1" justifyContent="center" alignItems="center">
                <Text fontSize="1.3rem">
                  {awardPosition}
                  {positionData.label}
                </Text>
              </Flex>
              <Flex flex="2" h={"auto"}>
                <Text fontSize={{ base: "1rem", lg: "1.4rem" }}>
                  {!isNumber &&
                  (award as BaseResponse<ChampionshipAward>)?.entity
                    .description != null
                    ? (award as BaseResponse<ChampionshipAward>)?.entity
                        .description
                    : isNumber
                      ? "???"
                      : convertValueToMoney(award.entity.value)}
                </Text>
              </Flex>
              <Flex flex="1" justifyContent="center" alignItems="center">
                <Img
                  src={!isNumber && file ? file.url : positionData.pathIcon}
                  width="50px"
                  height="50px"
                />
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default Awards;
