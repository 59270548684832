import { useEffect, useState } from "react";
import { ContainerTimer, TimeBox, TimeLabel, TimeValue } from "./styles";

const Countdown = ({ targetDate, primary, textColor }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = new Date(targetDate).getTime() - now;

      if (distance <= 0) {
        clearInterval(interval);
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <ContainerTimer>
      <TimeBox borderColor={primary}>
        <TimeValue textColor={textColor}>
          {String(timeLeft.days).padStart(2, "0")}
        </TimeValue>
        <TimeLabel textColor={textColor}>Dias</TimeLabel>
      </TimeBox>
      <TimeBox borderColor={primary}>
        <TimeValue textColor={textColor}>
          {String(timeLeft.hours).padStart(2, "0")}
        </TimeValue>
        <TimeLabel textColor={textColor}>Horas</TimeLabel>
      </TimeBox>
      <TimeBox borderColor={primary}>
        <TimeValue textColor={textColor}>
          {String(timeLeft.minutes).padStart(2, "0")}
        </TimeValue>
        <TimeLabel textColor={textColor}>Minutos</TimeLabel>
      </TimeBox>
      <TimeBox borderColor={primary}>
        <TimeValue textColor={textColor}>
          {String(timeLeft.seconds).padStart(2, "0")}
        </TimeValue>
        <TimeLabel textColor={textColor}>Segundos</TimeLabel>
      </TimeBox>
    </ContainerTimer>
  );
};

export default Countdown;
