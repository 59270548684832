import useEvent from "@hooks/useEvent.ts";
import {
  EventAreaTitle,
  ListCards,
} from "@pages/homePage/components/eventArea/style.ts";

import EventCardView from "@components/EventCardView";
import { motion } from "framer-motion";

const EventArea = () => {
  const { listEvents } = useEvent();

  return (
    <>
      <EventAreaTitle>EVENTOS DISPONIVEIS</EventAreaTitle>
      <ListCards>
        {listEvents?.map((item, index) => {
          const card = item.files?.find(
            (file) => file.business_type === "EVENT_CARD"
          )?.url;

          return (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, amount: 0.3 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="bg-white p-4 rounded-xl shadow-md"
            >
              <EventCardView
                key={item.id}
                backgroundImage={card}
                url={item?.entity?.url_context}
                event={item.entity}
              />
            </motion.div>
          );
        })}
      </ListCards>
    </>
  );
};

export default EventArea;
