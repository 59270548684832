import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ModalHeader,
  Select,
} from "@chakra-ui/react";
import Modal from "@components/template/modal";
import useUser from "@hooks/useUser";
import { Genders } from "@interfaces/user.interface";

import InputMask from "react-input-mask";

type Props = {
  isOpen: boolean;
  handlerClose: () => void;
};

const UpdateProfileModal: React.FC<Props> = ({ isOpen, handlerClose }) => {
  const { userAuth, formUpdateProfileUser } = useUser();

  const {
    formRegisterProfileUser,
    handleSubmitProfileUser,
    resetFormProfile,
    isSubmitted,
    errors,
    isValid,
  } = formUpdateProfileUser;

  const onClose = () => {
    resetFormProfile();
    handlerClose();
  };

  const handlerSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    handleSubmitProfileUser(e);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      handlerCancel={onClose}
      title="Editar Informações"
      size="5xl"
      bgColor="#0C1A27"
      isFooter={false}
      isHeader={false}
    >
      <ModalHeader
        color="#ffffff"
        fontSize={"25px"}
        fontWeight={"bold"}
        alignSelf="center"
      >
        <p>EDITE INFORMAÇÕES</p>
      </ModalHeader>
      <Flex as="form" onSubmit={handlerSubmit} gap="20px" flexDir="column">
        <Flex gap={"30px"} flexDir={{ base: "column", lg: "row" }}>
          <FormControl display="flex" flexDir="column">
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="name" m="0">
              Nome
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input color="#ffffff" {...formRegisterProfileUser("name")} />
              {isSubmitted && errors.name && (
                <FormHelperText color="#E94560">
                  {errors.name.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
          <FormControl display="flex" flexDir="column">
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="email" m="0">
              E-mail
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input color="#ffffff" value={userAuth?.email} isReadOnly name="e-mail" id="e-mail" />
            </Flex>
          </FormControl>
        </Flex>
        <Flex gap={"30px"} flexDir={{ base: "column", lg: "row" }}>
          <FormControl display="flex" flexDir="column">
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="nick" m="0">
              Nick
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input color="#ffffff" {...formRegisterProfileUser("nick")} />
              {isSubmitted && errors.nick && (
                <FormHelperText color="#E94560">
                  {errors.nick.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
          <FormControl display="flex" flexDir="column">
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="cpf" m="0">
              Cpf
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input
                as={InputMask}
                alwaysShowMask={false}
                mask={"***.***.***-**"}
                color="#ffffff"
                isReadOnly={userAuth?.cpf !== null}
                {...formRegisterProfileUser("cpf")}
              />
              {isSubmitted && errors.cpf && (
                <FormHelperText color="#E94560">
                  {errors.cpf.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
        </Flex>
        <Flex gap={"30px"} flexDir={{ base: "column", lg: "row" }}>
          <FormControl display="flex" flexDir="column">
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="name" m="0">
              Telefone
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input
                as={InputMask}
                alwaysShowMask={true}
                mask="(**) *****-****"
                color="#ffffff"
                {...formRegisterProfileUser("phone")}
              />
              {isSubmitted && errors.phone && (
                <FormHelperText color="#E94560">
                  {errors.phone.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
          <FormControl display="flex" flexDir="column">
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="gender" m="0">
              Gênero
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Select
                {...formRegisterProfileUser("gender")}
                style={{ color: "#ffffff"}}
                name="gender"
              >
                <option style={{ color: "#000000"}} value={Genders.MALE}>Masculino</option>
                <option style={{ color: "#000000"}} value={Genders.FEMALE}>Feminino</option>
                <option style={{ color: "#000000"}} value={Genders.OTHER}>Outro</option>
                <option style={{ color: "#000000"}} value={Genders.NOT_INFORMED}>Não Informar</option>
              </Select>
              {isSubmitted && errors.phone && (
                <FormHelperText color="#E94560">
                  {errors.phone.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
        </Flex>
        <Button
          type="submit"
          color="#ffffff"
          bg="#E94560"
          w="150px"
          h="40px"
          alignSelf={"center"}
          marginTop={"15px"}
          disabled={!isValid}
        >
          Salvar
        </Button>
      </Flex>
    </Modal>
  );
};

export default UpdateProfileModal;
