import {
  SocialLoginProvierType,
  INormalLogin,
  IRegister,
  ILoginResponse,
  IRegisterRequest,
  IChangePasswordRequest,
  IChangePassword,
  IResetPassword,
} from "@interfaces/auth.interface";
// import {
//   ReactFacebookFailureResponse,
//   ReactFacebookLoginInfo,
// } from "react-facebook-login";
import authService from "@services/auth.service";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { extractNumbers } from "@utils/extractNumbers";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { QUERY_KEY_USER } from "../constants/queryKeys.constants";
import { UserContext } from "@context/UserContext";

const useAuth = () => {
  const { updateToken, isAuthenticated, isLoadingAuth } =
    useContext(UserContext);
  const queryClient = useQueryClient();
  const {
    postLoginSocial,
    postLogin,
    postRegister,
    postChangePassword,
    postResetPassword,
  } = authService();
  const navigate = useNavigate();

  const mutationRegister = useMutation<
    ILoginResponse,
    undefined,
    IRegisterRequest
  >({
    mutationFn: postRegister,
    onSuccess: () => {
      toast.success("Cadastro feito com sucesso");
      navigate("/login");
    },
    onError: () => toast.error("Tivemos problema o cadastro."),
  });

  const mutationChangePassword = useMutation<
    void,
    undefined,
    IChangePasswordRequest
  >({
    mutationFn: postChangePassword,
    onSuccess: () => {
      toast.success("Senha resetada com sucesso!");
    },
    onError: () => toast.error("Tivemos problema na troca de senha."),
  });

  const mutationResetPassword = useMutation<void, undefined, IResetPassword>({
    mutationFn: postResetPassword,
    onSuccess: () => {
      toast.success(
        "Solicitação de esquecer senha feita com sucesso! Verifique seu email."
      );
    },
    onError: () =>
      toast.error("Tivemos problema na solicitação de esquecer senha."),
  });

  const mutationLogin = useMutation<ILoginResponse, undefined, INormalLogin>({
    mutationFn: postLogin,
    onSuccess: async (response) => {
      updateToken(response.token);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_USER.PROFILE],
      });
      navigate(`/profile/${response.id}`);
    },
  });

  // const loginSocialFacebook = async (
  //   response: ReactFacebookLoginInfo | ReactFacebookFailureResponse | any
  // ) => {
  //   const result = await postLoginSocial({
  //     token: response.accessToken,
  //     providerType: SocialLoginProvierType.FACEBOOK,
  //   });

  //   localStorage.setItem("programers.token", JSON.stringify(result.token));
  //   navigate("/profile");
  // };

  const loginSocialGoogle = async (token: string) => {
    const result = await postLoginSocial({
      token: token,
      providerType: SocialLoginProvierType.GOOGLE,
    });

    updateToken(result.token);
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_USER.PROFILE],
    });
    navigate(`/profile/${result.id}`);
  };

  const loginSocialSteam = async (accessToken: string) => {
    const result = await postLoginSocial({
      token: accessToken,
      providerType: SocialLoginProvierType.STEAM,
    });

    localStorage.setItem("programers.token", JSON.stringify(result.token));
    navigate("/profile");
  };

  const normalLogin: SubmitHandler<INormalLogin> = async (values, event) => {
    event?.preventDefault();
    await mutationLogin.mutateAsync(values);
  };

  const submiterResetPassword: SubmitHandler<IResetPassword> = async (
    values,
    event
  ) => {
    event?.preventDefault();
    await mutationResetPassword.mutateAsync(values);
  };

  const handleLoginSteam = () => {
    const returnUrl = encodeURIComponent(
      `${window.location.origin}/auth/steam/callback`
    );
    const steamUrl = `https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.mode=checkid_setup&openid.return_to=${returnUrl}&openid.realm=${encodeURIComponent(window.location.origin)}&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select`;
    window.location.href = steamUrl;
  };

  const submiteRegister: SubmitHandler<IRegister> = async (values, event) => {
    event?.preventDefault();
    const { passwordConfirm, ...data } = values;
    values.cpf = extractNumbers(values.cpf);
    values.phone = extractNumbers(values.phone);
    await mutationRegister.mutateAsync(data);
  };

  const submiteChangePassword: SubmitHandler<IChangePassword> = async (
    values,
    event
  ) => {
    event?.preventDefault();
    const { passwordConfirm, ...data } = values;
    await mutationChangePassword.mutateAsync(data);
  };

  const handleSetToken = (token: string | null) => {
    updateToken(token);
    if (token) {
      localStorage.setItem("programers.token", token);
    } else {
      localStorage.removeItem("programers.token");
    }
  };

  const logout = () => {
    handleSetToken(null);
    navigate("/");
  };

  return {
    isAuthenticated,
    isLoadingAuth,
    // loginSocialFacebook,
    loginSocialGoogle,
    loginSocialSteam,
    normalLogin,
    handleLoginSteam,
    submiteRegister,
    submiteChangePassword,
    submiterResetPassword,
    logout,
    handleSetToken,
  };
};
export default useAuth;
