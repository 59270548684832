import { Flex } from "@chakra-ui/react";
import GamePillItem from "./GamePillItem";
import { useState } from "react";
import useGamePublic from "@hooks/useGamePublic.ts";

interface GamePillsProps {
  onSelectGame: (gameId: string) => void;
}

const GamePills: React.FC<GamePillsProps> = ({ onSelectGame }) => {
  const [selectedGame, setSelectedGame] = useState<string>("");
  const { gamesFilter } = useGamePublic();

  const handleSelectGame = (gameId: string) => {
    const newSelection = selectedGame === gameId ? null : gameId;
    setSelectedGame(newSelection);
    onSelectGame(newSelection);
  };

  return (
    <Flex gap={4} p={4} flexWrap="wrap">
      {gamesFilter.map((game) => (
        <GamePillItem
          key={game.id}
          label={game.name}
          icon={game.icon}
          isSelected={selectedGame === game.id}
          onClick={() => handleSelectGame(game.id)}
        />
      ))}
    </Flex>
  );
};

export default GamePills;
