import { Flex, Spinner, Text } from "@chakra-ui/react";
import WiningBracket from "./WiningBracket";
import LosingBracket from "./LosingBracket";
import { ChampionshipsTournament } from "@interfaces/championships.interface";

type Props = {
  tournaments: ChampionshipsTournament;
  isLoading: boolean;
  isWining?: boolean;
  isLosing?: boolean;
  style?: React.CSSProperties;
};

const TableChampionships: React.FC<Props> = ({
  tournaments,
  isLoading,
  isLosing = true,
  isWining = true,
  style,
}) => {
  return (
    <Flex
      position="relative"
      flexDirection="column"
      overflowY="auto"
      p="1px"
      style={style}
      flex={"100%"}
    >
      {isLoading ? (
        <Flex
          w="100%"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          gap="12px"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#E94560"
            size="xl"
          />
          <Text color="#E94560" fontWeight="bold" fontSize="20px">
            Carregando...
          </Text>
        </Flex>
      ) : (
        <>
          {isWining && <WiningBracket rounds={tournaments.wining} />}

          <div style={{ height: 50 }}></div>
          {isLosing && <LosingBracket rounds={tournaments.lower} />}
        </>
      )}
    </Flex>
  );
};

export default TableChampionships;
