import UserContextProvider from "@context/UserContext";
import queryClient from "@config/queryClient.config";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ChakraProvider } from "@chakra-ui/react";
import { mainTheme } from "../global/thema";
import { SocketContextProvider } from "@context/SocketContext";

type ProvidersProps = {
  children: ReactNode;
};

export default function Providers({ children }: ProvidersProps) {
  const appGoogleKey = import.meta.env.VITE_SOME_APP_GOOGLE_KEY;

  return (
    <ChakraProvider theme={mainTheme}>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <SocketContextProvider>
            <GoogleOAuthProvider clientId={appGoogleKey}>
              {children}
            </GoogleOAuthProvider>
          </SocketContextProvider>
        </UserContextProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ChakraProvider>
  );
}
