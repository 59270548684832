import { useRef, useState } from "react";
import {
  Box,
  List,
  ListIcon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { IoIosSend } from "react-icons/io";
import { SearchUserResponse } from "@interfaces/user.interface";
import * as S from "./style";
import { BaseResponse } from "@interfaces/base";
import { FileBussinessType } from "@interfaces/file.interface";
import { Search } from "lucide-react";

type Props = {
  select: (value: string) => void;
  suggestions: (name: string) => BaseResponse<SearchUserResponse>[] | undefined;
};

const AutocompleteInput: React.FC<Props> = ({ suggestions, select }) => {
  const initialFocusRef = useRef(null);
  const [input, setInput] = useState<string>("");
  const teamMembers = suggestions(input);

  const sendInvit = (value: string) => {
    select(value);
    setInput("");
  };

  return (
    <Box>
      <Popover
        initialFocusRef={initialFocusRef}
        isOpen={teamMembers !== undefined}
        isLazy
        lazyBehavior="unmount"
        matchWidth
      >
        <PopoverTrigger>
          <S.SearchInput>
            <S.Input
              ref={initialFocusRef}
              placeholder="Pesquisar jogador"
              onChange={(e) => setInput(e.target.value)}
            />
            <Search />
          </S.SearchInput>
        </PopoverTrigger>

        <PopoverContent
          className="ScrollBar"
          p={4}
          color="white"
          bg="blue.800"
          borderColor="blue.900"
          w="100%"
          height="354px"
          overflowY="auto"
        >
          <PopoverBody>
            {teamMembers !== undefined && teamMembers?.length > 0 ? (
              <List spacing={5}>
                {teamMembers?.map((suggestion) => {
                  const userAvatar = suggestion.files.find(
                    (item) =>
                      item.business_type ===
                      FileBussinessType.USER_PROFILE_PHOTO
                  );
                  return (
                    <S.ListItem
                      key={suggestion.entity.id}
                      cursor="pointer"
                      onClick={() => sendInvit(suggestion.entity.id)}
                    >
                      <S.PlayerInfo>
                        <S.AvatarProfile src={userAvatar?.url!} size="md" />
                        {suggestion.entity.name}
                        <S.Send>
                          <ListIcon
                            as={IoIosSend}
                            color="#e9445f"
                            w={30}
                            h={30}
                          />
                        </S.Send>
                      </S.PlayerInfo>
                    </S.ListItem>
                  );
                })}
              </List>
            ) : (
              "Desculpe, não encontramos nenhum usuário com esse nome."
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default AutocompleteInput;
