import { Text, Flex, Image, Button, Link } from "@chakra-ui/react";
import { INotificationInvite } from "@interfaces/notification.interface";
import { TeamNotificationUserStatus } from "@interfaces/team.interface";
import useNotification from "@hooks/useNotification";

// TODO! Refatorar
type Props = {
  type: string;
  invite: INotificationInvite;
};

const NotificationInvitation: React.FC<Props> = ({ invite, type }) => {
  const { acceptionInvitationNotification, declineInvitationNotification } =
    useNotification();

  return (
    <Flex gap={"12px"}>
      <Link href={`team/${invite.team.id}`}>
        <Image
          src={
            invite.team.profilePhoto ||
            "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
          }
          w={"55px"}
          h={"55px"}
          borderRadius={"100%"}
        />
      </Link>
      {type === "TEAM_INVITE" && (
        <Flex flex={"1"} flexDirection={"column"} gap={"10px"}>
          <Text as={"span"}>
            <Text as={"span"} fontWeight={"bold"}>
              {invite.userWhoInvited.nick}
            </Text>{" "}
            convidou você para o time{" "}
            <Text as={"span"} fontWeight={"bold"}>
              {invite.team.name}
            </Text>
          </Text>
          <Text as={"span"} fontWeight={"bold"} color={"#7eb764"}>
            {invite.status === TeamNotificationUserStatus.ACCEPTED && (
              <Text as={"span"} fontWeight={"bold"} color={"#7eb764"}>
                Aceito
              </Text>
            )}
            {invite.status === TeamNotificationUserStatus.DECLINED && (
              <Text as={"span"} fontWeight={"bold"} color={"#e94560"}>
                Recusado
              </Text>
            )}
          </Text>

          {invite.status === TeamNotificationUserStatus.PENDING && (
            <Flex justifyContent={"start"} gap={{ base: "25px", lg: "50px" }}>
              <Button
                variant={"outline"}
                color={"#e94560"}
                borderColor={"#e94560"}
                _hover={{ backgroundColor: "none" }}
                onClick={() =>
                  acceptionInvitationNotification(invite.team.id, invite.id)
                }
              >
                Aceitar
              </Button>
              <Button
                variant={"outline"}
                color={"#ffffff"}
                borderColor={"#ffffff"}
                _hover={{ backgroundColor: "none" }}
                onClick={() =>
                  declineInvitationNotification(invite.team.id, invite.id)
                }
              >
                Ignorar
              </Button>
            </Flex>
          )}
        </Flex>
      )}
      {type === "TEAM_JOIN" && (
        <Flex flex={"1"} flexDirection={"column"} gap={"10px"}>
          <Text as={"span"}>
            <Text as={"span"} fontWeight={"bold"}>
              {invite.user.nick}
            </Text>{" "}
            aceitou o convite para o time{" "}
            <Text as={"span"} fontWeight={"bold"}>
              {invite.team.name}
            </Text>
          </Text>
        </Flex>
      )}
      {type === "REFUSED" && (
        <Flex flex={"1"} flexDirection={"column"} gap={"10px"}>
          <Text as={"span"}>
            <Text as={"span"} fontWeight={"bold"}>
              {invite.user.nick}
            </Text>{" "}
            recusou o convite para o time{" "}
            <Text as={"span"} fontWeight={"bold"}>
              {invite.team.name}
            </Text>
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default NotificationInvitation;
