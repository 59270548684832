import { Search } from "lucide-react";
import {
  Checkbox,
  CheckboxLabel,
  Input,
  Label,
  SearchInput,
} from "@pages/social/filter/styles.ts";
import {
  FilterPill,
  SearchBox,
  SearchSection,
} from "@pages/social/filter/team/styles.ts";
import GamePills from "@components/gamesPill/GamePills.tsx";
import {
  CardList,
  NotFoundMessage,
  ResultSection,
} from "@pages/social/styles.ts";
import TeamCard from "@components/teamCard";
import Pagination from "@components/pagination";
import { ChangeEvent, useState } from "react";
import useTeamPublic from "@hooks/useTeamPublic.ts";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";
import defaultbanner from "@assets/ativos/png/defaultbanner.png";

const TeamFilter: React.FC = () => {
  const { searchTeamByNameAndTag } = useTeamPublic();

  const [currentPage, setCurrentPage] = useState(1);

  const [isChecked, setIsChecked] = useState(true);
  const [inputValue, setInputValue] = useState<string>("");
  const [gameIdSelected, setGameIdSelected] = useState<string>(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setInputValue(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleGameSelection = (gameId: string) => {
    setGameIdSelected(gameId);
  };

  const searchParams: ISearchPagination = {
    search: inputValue,
    page: currentPage,
    limit: 20,
    order: isChecked ? "asc" : "desc",
    entityId: gameIdSelected,
  };

  const { data } = searchTeamByNameAndTag(searchParams);

  return (
    <>
      <SearchSection>
        <SearchBox>
          <Label>Buscar por time</Label>
          <SearchInput>
            <Input
              type="text"
              placeholder="Buscar time"
              id="search-input-team"
              value={inputValue}
              onChange={handleInputChange}
            />
            <Search />
          </SearchInput>

          <div onChange={handleCheckboxChange}>
            <Checkbox type="checkbox" id="order-teams" />

            <CheckboxLabel htmlFor="order-teams">
              Ordem Alfabética
            </CheckboxLabel>
          </div>
        </SearchBox>
        <FilterPill>
          <GamePills onSelectGame={handleGameSelection} />
        </FilterPill>
      </SearchSection>
      <ResultSection>
        <>
          <CardList>
            {data && data.data && data.data.length > 0 ? (
              data.data.map((team) => {
                const profilePhoto = team.files?.find(
                  (file) => file.business_type === "TEAM_PROFILE_PHOTO"
                )?.url;

                const bannerPhoto = team.files?.find(
                  (file) => file.business_type === "TEAM_PROFILE_BANNER"
                )?.url;

                const avatars =
                  team.entity?.team_members?.flatMap((member) =>
                    member?.files?.length > 0
                      ? member.files.map((file) => ({
                          id: member.entity.user_id,
                          name: member.entity.user_name,
                          url: "/profile/" + member.entity.user_id,
                          avatar: file.url,
                        }))
                      : {
                          id: member.entity.user_id,
                          name: member.entity.user_name,
                          url: "/profile/" + member.entity.user_id,
                          avatar: null,
                        }
                  ) || null;

                return (
                  <TeamCard
                    id={team.entityId}
                    key={team.entityId}
                    backgroundImage={bannerPhoto || defaultbanner}
                    avatar={profilePhoto}
                    title={team.entity.name}
                    team_members_count={team.entity.team_members_count}
                    avatars={avatars}
                  />
                );
              })
            ) : (
              <NotFoundMessage>
                Equipes não encontradas para este filtro
              </NotFoundMessage>
            )}
          </CardList>
        </>
        <Pagination
          currentPage={data?.page}
          totalPages={data?.totalPage}
          onPageChange={handlePageChange}
        />
      </ResultSection>
    </>
  );
};

export default TeamFilter;
