import styled from "styled-components";

export const EventContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
`;

export const ListCards = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  margin-top: 4rem; 
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-content: center;
  margin-top: 4rem;
  width: 100%;
`;
export const EventAreaTitle = styled.h2`
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  font-family: 'Quinn', Poppins, sans-serif;
  text-transform: uppercase;
  padding-bottom: 2rem;
  margin-top: 5.375rem;
`;