import { Flex, Spinner as SpinnerChakra } from "@chakra-ui/react";

const Spinner: React.FC = () => {
  return (
    <Flex w="100%" justifyContent="center">
      <SpinnerChakra
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#E94560"
        size="xl"
      />
    </Flex>
  );
};

export default Spinner;