import React from "react";
import { ActionButton, PageButton, PaginationContainer } from "./styles";
import { ChevronLeft, ChevronRight } from "lucide-react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  if (totalPages <= 1) {
    return null;
  }

  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 4;

    const startPage = Math.floor((currentPage - 1) / maxVisiblePages) * maxVisiblePages + 1;
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageButton
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </PageButton>
      );
    }

    return pages;
  };

  return (
    <PaginationContainer>
      {currentPage > 1 && ( // Oculta o botão "Previous" na página 1
        <ActionButton onClick={handlePrevious}>
          <ChevronLeft />
        </ActionButton>
      )}
      {renderPageNumbers()}
      {currentPage < totalPages && ( // Oculta o botão "Next" na última página
        <ActionButton onClick={handleNext}>
          <ChevronRight />
        </ActionButton>
      )}
    </PaginationContainer>
  );
};

export default Pagination;
