import styled from "styled-components";
import { device } from "@utils/index";

export const SearchSection = styled.div`
  display: flex;
  margin-top: 20px;
  background-color: var(--card-body-background-color);
  flex-direction: row;
  width: 100%;
  gap: 20px;
  border-radius: 5px;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.mobileLarge} {
    flex-direction: column;
  }
`;

export const SearchBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  display: flex;
  width: fit-content;
  flex-direction: column;
`;
export const FilterPill = styled.div`
  display: flex;
  margin-top: 4.8rem;

  @media ${device.tablet} {
    margin-top: 0.1rem;
  }

  @media ${device.mobileLarge} {
    margin-top: 0.1rem;
  }
`;
