import styled from "styled-components";
import { device } from "@utils/index.ts";

export const Card = styled.div`
    width: 390px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--card-background-color);
    border: 1px solid transparent;
    border-radius: 5px;
    transition: border-color 0.3s ease;

    @media ${device.tablet} {
        width: 100%;
    }

    @media ${device.mobileMedium} { 
        max-width:265px;
    }

    @media ${device.tabletSmall} {
        max-width:240px;
    }
    
    @media ${device.notebook} {
        width: 355px;
    }

    @media ${device.laptop} {
       width: 200px;
    }
 

    &:hover {
        border-color: var(--layout-color);
        cursor: pointer;
    }
`;

export const Header = styled.div`
  position: relative;
  height: 150px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

export const Avatar = styled.img`
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Body = styled.div`
  text-align: center;
  border-top: 0.003rem solid var(--chakra-colors-gray-800);
`;

export const Title = styled.h3`
  padding: 10px;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--white);
`;

export const Nick = styled.h2`
  padding: 10px;
  font-weight: normal;
  font-size: 0.9rem;
  color: var(--layout-color);
`;

export const SmallAvatarPlaceholder = styled.div`
  border-radius: 50%;
  background-color: var(--card-body-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  box-shadow: 0 7px 4px rgba(0, 0, 1, 0.1);
`;
