import HeaderRight from "./components/right";
import HeaderLeft from "./components/left";
import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSocket } from "@hooks/useSocket";
import useNotification from "@hooks/useNotification";

const Header = () => {
  const { socketListener, socketManager } = useSocket();
  const { newNotificationSocket } = useNotification();

  useEffect(() => {
    socketListener("NEW_NOTIFICATION", newNotificationSocket);
  }, [socketManager]);

  return (
    <Flex
      w={{ md: "90%", lg: "100%" }}
      justifyContent={"space-between"}
      alignItems={"center"}
      zIndex={"2"}
      gap={"1rem"}
      marginInline={"auto"}
      color={"var(--text-color)"}
    >
      <HeaderLeft />
      <HeaderRight />
    </Flex>
  );
};

export default Header;
