import { SocketContext } from "@context/SocketContext";
import { ESocketScenarioEventNames } from "@interfaces/socket.interface";
import { useContext } from "react";

export const useSocket = () => {
  const { socketManager } = useContext(SocketContext);

  const socketListener = (
    eventName: keyof typeof ESocketScenarioEventNames,
    callback: (body: any) => void
  ) => {
    const haveListeners = socketManager?.hasListeners(
      ESocketScenarioEventNames[eventName]
    );

    if (haveListeners) return;

    socketManager?.on(ESocketScenarioEventNames[eventName], callback);

    return () => {
      socketManager?.off(ESocketScenarioEventNames[eventName], callback);
    };
  };

  return { socketManager, socketListener };
};
