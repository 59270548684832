import { device } from "@utils/index";
import styled from "styled-components";

export const SideBarContainer = styled.div`
  position: fixed;
  z-index: 10;
  left: 0rem;
  display: flex;
  align-items: unset;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;

    @media ${device.tabletSmall} {
        display: none;
    }
`;

export const SideBarButton = styled.a`
  width: 4rem;
  height: 3.75rem;
  border-radius: 0px 6px 6px 0px;
  > img {
    width: 2rem;
    height: auto;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0px 6px 6px 0px;
  border: 0;

  background: #e94560;
  box-shadow: 0rem 0rem 1rem #e94560;
  opacity: 1;
  cursor: pointer;

  transition-duration: 0.5s;

  &:hover {
    transition-duration: 0.5s;
    transform: scale(1.1);
  }

  @media ${device.mobileLarge} {
    width: 3rem;
    height: 2.75rem;
    margin: 1rem 0;

    > img {
      width: 1.5rem;
      height: auto;
    }
  }
`;
