import useChampionship from "@hooks/useChampionship";
import Card from "./Card/Card";
import * as S from "./styles";
import {
  CarouselContainer,
  MobileCarouselWrapper,
  SliderWrapper,
} from "./styles";
import { Link } from "react-router-dom";
import { ChampionshipsTranslate } from "@translate/championships.translate";
import Slider from "react-slick";

const ChampionshipsEventSession = ({ titleSection, event }) => {
  let { listChampionshipsFilterById } = useChampionship();

  const entityIds = event?.entity?.event_championships_entity?.map(
    (item) => item?.entity?.championship.id
  );

  const listChampionships = listChampionshipsFilterById(entityIds);

  var settings = {
    dots: listChampionships.length > 1,
    infinite: listChampionships.length > 1,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(4, listChampionships.length),
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: listChampionships.length > 1,
    centerPadding: listChampionships.length > 1 ? "1.75rem" : "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode:false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let primary = "red";
  let secondary = "red";
  let textColor = "white";
  const configPage = event?.entity?.event_page_entity;

  primary = configPage?.page_config?.primary;
  secondary = configPage?.page_config?.secondary;
  textColor = configPage?.page_config?.textColor;

  return (
    <S.CampeonatosContainer>
      <S.CampeonatosTitle>{titleSection}</S.CampeonatosTitle>
      {listChampionships && listChampionships.length > 0 ? (
        listChampionships.length === 1 ? (
          // Se houver apenas 1 campeonato, renderiza o Card diretamente
          <MobileCarouselWrapper key={listChampionships[0]?.entity?.id}>
            <Link to={`/championship/${listChampionships[0]?.entity?.id}`}>
              <Card
                key={listChampionships[0].entity.id}
                championshipName={listChampionships[0].entity.name}
                championshipImg={
                  listChampionships[0]?.files?.find(
                    (file) => file?.business_type === "CHAMPIONSHIP_CARD"
                  )?.url
                }
                textColor={textColor}
                gameImg={
                  listChampionships[0]?.entity?.game?.files?.find(
                    (file) => file?.business_type === "GAMES_ICON"
                  )?.url
                }
                primary={primary}
                secondary={secondary}
                date={new Date(listChampionships[0].entity.start_date)}
                currentUsers={
                  listChampionships[0].entity.registered_participants_count
                }
                maxUsers={listChampionships[0].entity.participant_limit}
                squad={ChampionshipsTranslate(
                  listChampionships[0].entity.modality
                )}
              />
            </Link>
          </MobileCarouselWrapper>
        ) : (
          // Se houver mais de 1 campeonato, usa o slider
          <CarouselContainer>
            <SliderWrapper hasOneItem={false} textColor={textColor}>
              <Slider {...settings}>
                {listChampionships.map((item) => {
                  const file = item?.files?.find(
                    (file) => file?.business_type === "CHAMPIONSHIP_CARD"
                  );
                  const game = item?.entity?.game?.files?.find(
                    (file) => file?.business_type === "GAMES_ICON"
                  );
                  return (
                    <MobileCarouselWrapper key={item?.entity?.id}>
                      <Link to={`/championship/${item?.entity?.id}`}>
                        <Card
                          key={item.entity.id}
                          championshipName={item.entity.name}
                          championshipImg={file?.url}
                          textColor={textColor}
                          gameImg={game?.url}
                          primary={primary}
                          secondary={secondary}
                          date={new Date(item.entity.start_date)}
                          currentUsers={
                            item.entity.registered_participants_count
                          }
                          maxUsers={item.entity.participant_limit}
                          squad={ChampionshipsTranslate(item.entity.modality)}
                        />
                      </Link>
                    </MobileCarouselWrapper>
                  );
                })}
              </Slider>
            </SliderWrapper>
          </CarouselContainer>
        )
      ) : (
        ""
      )}
    </S.CampeonatosContainer>
  );
};

export default ChampionshipsEventSession;
