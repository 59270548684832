import { SocialLoginProvierType } from "./auth.interface";
import { BaseResponse } from "./base";

export interface IUser {
  id: string;
  name: string;
  email: string;
  created_at: Date;
  updated_at: Date;
  active: boolean;
  provider: SocialLoginProvierType;
  phone: string;
  cpf: string;
  email_valid: boolean;
  reset_token: string;
  lol_id: string;
  google_id: string;
  facebook_id: string;
  steam_id: string;
  instagram_id: string;
  gender: Genders;
  nick: string;
  role: string;
  picture: string;
  user_equipament: [];
  user_social: [];
  teams: BaseResponse<IUserTeams>[];
  user_pix: [];
  user_integrations: [];
}

export interface IUserSelect {
  id: string;
  name: string;
  picture: string;
  selected: boolean;
}

export enum Genders {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
  NOT_INFORMED = "NOT_INFORMED",
}

export interface IUserTeams {
  id: string;
  name: string;
  owner_id: "180c5f0f-4584-4ef0-9717-a054af3512a2";
  organization_id: string | null;
  is_active: boolean;
  description: string;
  team_tag: string;
  created_at: string;
  updated_at: string;
  team_members_count: number;
}

export type SearchUserResponse = Omit<
  IUser,
  "password" | "phone" | "email_valid"
>;

export type UpdateUser = Pick<
  IUser,
  "name" | "gender" | "nick" | "phone" | "cpf"
> & {};

export interface IUserEquipament {
  id: string;
  user_id: string;
  created_at: Date;
  updated_at: Date;
  os: string;
  mouse: string;
  mousePad: string;
  headphone: string;
  keyboard: string;
  cpu: string;
  memoryRam: string;
  storage: string;
  graphicsCard: string;
  cabinet: string;
  monitor: string;
  smartphone: string;
  console: string;
  type: UserEquipmentTypes;
  brand_model: string;
  controll: string;
  is_main: boolean;
  cooler: string;
  microphone: string;
}

export enum UserEquipmentTypes {
  COMPUTER = "COMPUTER",
  NOTEBOOK = "NOTEBOOK",
  PC = "PC",
  CONSOLE = "CONSOLE",
  TABLET = "TABLET",
  SMARTPHONE = "SMARTPHONE",
}

export type UserSetupForm = Partial<
  Record<UserEquipmentTypes, BaseResponse<IUserEquipament>>
>;

export type IRequestSetup = Partial<
  Omit<IUserEquipament, "user_id" | "created_at" | "updated_at">
>;
