import styled from "styled-components";
import { device } from "@utils/index.ts";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ bgColor }) => bgColor || "#E63946"};
  font-family: "Saira";
`;

export const Header = styled.div`
  width: 100%;
`;

export const TitleContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor || "#E63946"};
  padding: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  flex-direction: column;
  font-family: "Saira";

  @media ${device.tablet} {
    margin-bottom: 1rem;
    padding: 1rem;
  }
  @media ${device.tabletSmall} {
    padding: 1rem;
  }
`;

export const SectionBanner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: fit-content;
  gap: 10px;
  font-family: "Saira";

  @media ${device.tablet} {
    padding: 1rem;
    padding-bottom: 4rem;
  }

  @media ${device.tabletSmall} {
    flex-direction: column-reverse;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Saira";
`;

export const Counter = styled.div`
  display: flex;

  width: 100%;
  justify-content: center;
  padding: 1rem;
  margin-top: -4rem;
  background: ${({ bgColor }) => bgColor || "#E63946"};
  color: ${({ textColor }) => textColor || "#FFF"};
`;

export const TitleEvent = styled.h1`
  word-wrap: break-word;
  margin-top: 4rem;
  font-weight: bold;
  font-size: 5rem;
  color: ${({ textColor }) => textColor || "#FFF"};
  font-family: "Saira";
  @media ${device.laptop} {
    font-size: 3rem;
    margin-top: 3rem;
  }
  @media ${device.tabletSmall} {
    margin-top: 1rem;
    font-size: 2.3rem;
  }
`;

export const Image = styled.img`
  margin-top: 4rem;
  width: 40%;
  @media ${device.tabletSmall} {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Saira";
  background: ${({ bgColor }) =>
    bgColor
      ? `linear-gradient(280deg, rgba(255,255,255,0) 0%, ${bgColor} 49%, rgba(255,255,255,0) 100%)`
      : "#1a1a1a"};

  color: ${({ textColor }) => textColor || "#FFF"};
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
  width: 80%;
  margin: 0 auto;
  height: 50px;

  @media ${device.tabletSmall} {
    font-size: 1.3rem;
  }
`;

export const Section = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-family: "Saira";
`;

export const SectionChampionship = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
`;

export const ListCards = styled.div`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  width: 60%;
  font-family: "Saira";
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-content: center;
  margin-top: 4rem;

  @media ${device.tabletSmall} {
    width: 100%;
    padding: 1rem;
  }

  @media ${device.laptop} {
    width: 100%;
    padding: 3rem;
  }
  @media ${device.tablet} {
    width: 100%;
    padding: 1rem;
  }
`;
export const ListSchedule = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-top: 4rem;
  width: 60%;
  gap: 1rem;
  margin-bottom: 4rem;
  font-family: "Saira";

  @media ${device.laptop} {
    width: 80%;
  }
  @media ${device.tablet} {
    padding: 1rem;
    width: 100%;
  }
`;

export const EventDescription = styled.div`
  display: flex;
  margin-top: 5rem;
  font-size: 1.8rem;
  @media ${device.laptop} {
    font-size: 1.5rem;
    margin-top: 2rem;
  }

  color: ${({ textColor }) => textColor || "#E63946"};
`;

export const EventDetailsInfo = styled.div`
  display: flex;
  padding: 3rem;
  margin-top: -4rem;
  background: ${({ bgColor }) => bgColor || "#E63946"};
  align-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const HtmlDescription = styled.div`
  color: ${({ textColor }) => textColor || "#E63946"};

  font-size: 2rem;

  @media ${device.laptop} {
    font-size: 1.3rem;
  }
`;
