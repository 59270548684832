import { Box, Flex, Text } from "@chakra-ui/react";

import { LogoProGamersTV } from './styles';
import logoProTV from "@assets/ativos/png/Logo_tv.png";

const TwitchEmbedCard = () => {
  // const channels = ["progamersbrasil", "riotgames", "esl_csgo", "gaules"];
  const channels = ["progamersbrasil"];

  return (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      marginTop="7rem"
      gap="35px"
      backgroundColor="#0F1B34"
    >
      <LogoProGamersTV src={logoProTV} />
      <Flex w="100%" gap={6} flexWrap="wrap" justifyContent="center" marginBottom="2.75rem">
        {channels.map((channel) => (
          <Box
            key={channel}
            as="iframe"
            minW="45%"
            w={{ base: "100%", lg: channels.length > 1 ? "45%" : "95%" }}
            h={{ base: "250px", lg: "400px", xl: "800px" }}
            src={`https://player.twitch.tv/?channel=${channel}&parent=progamers.com.br`}
            frameBorder="0"
            scrolling="no"
            allowFullScreen={true}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default TwitchEmbedCard;
