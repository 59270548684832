import { useQuery } from "@tanstack/react-query";
import { FileBussinessType } from "@interfaces/file.interface";
import {
  optionsFetchSearchUserPagination,
  optionsFetchUserByIdPublic,
} from "@plugins/reactQuery/user.query-options";
import { IUserSelect } from "@interfaces/user.interface.ts";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";

const useUserPublic = (userId: string) => {
  const { data: user } = useQuery(optionsFetchUserByIdPublic(userId!));

  const files = user?.files || [];
  const teams = user?.entity.teams || [];

  const avatarFile = files.find(
    (item) => item.business_type === FileBussinessType.USER_PROFILE_PHOTO
  );

  const bannerFile = files.find(
    (item) => item.business_type === FileBussinessType.USER_PROFILE_BANNER
  );

  const searchUsersPagination = (searchParams: ISearchPagination) => {
    const { data: data } = useQuery(
      optionsFetchSearchUserPagination(searchParams)
    );
    return { data };
  };

  const searchUsersPaginationSelect = (searchParams: ISearchPagination
  ) => {
    const { data: data } = useQuery(
      optionsFetchSearchUserPagination(searchParams)
    );
    const players = convertToUserSelect(data?.data);
    return { players, data };
  };

  function convertToUserSelect(data: any[] | null | undefined): IUserSelect[] {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((item) => {
      const pictureUrl = item?.files?.[0]?.url || null;
      return {
        id: item?.entity?.id || null,
        name: item?.entity?.name || "Unknown",
        picture: pictureUrl,
        selected: false,
      };
    });
  }

  return {
    user: user?.entity,
    teams,
    files,
    avatarFile,
    bannerFile,
    searchUsersPagination,
    searchUsersPaginationSelect,
  };
};
export default useUserPublic;
