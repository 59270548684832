import useChampionship from "@hooks/useChampionship";
import Card from "./Card/Card";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { ChampionshipsTranslate } from "@translate/championships.translate";
import Slider from "react-slick";
import { CarouselContainer, MobileCarouselWrapper, SliderWrapper } from "./styles";

const ChampionshipsSession = ({ titleSection }) => {
  const { listChampionships } = useChampionship();

  var settings = {
    dots: listChampionships?.length > 1,
    infinite: listChampionships?.length > 1,
    arrows: false,
    speed: 500,
    slidesToShow: Math.min(4, listChampionships?.length),
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: listChampionships?.length > 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerMode:false,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <S.CampeonatosContainer>
      <S.CampeonatosTitle>{titleSection}</S.CampeonatosTitle>
      {listChampionships && listChampionships.length > 0 ? (
        listChampionships.length === 1 ? (
          
          <MobileCarouselWrapper  key={listChampionships[0].entity.id}>
            <Link to={`/championship/${listChampionships[0].entity.id}`}>
              <Card
                key={listChampionships[0].entity.id}
                championshipName={listChampionships[0].entity.name}
                championshipImg={listChampionships[0]?.files?.find(
                  (file) => file.business_type === "CHAMPIONSHIP_CARD"
                )?.url}
                gameImg={listChampionships[0]?.entity?.game?.files?.find(
                  (file) => file.business_type === "GAMES_ICON"
                )?.url}
                date={new Date(listChampionships[0]?.entity.start_date)}
                date_end={new Date(listChampionships[0]?.entity.end_date)}
                currentUsers={listChampionships[0].entity.registered_participants_count}
                maxUsers={listChampionships[0].entity.participant_limit}
                squad={ChampionshipsTranslate(listChampionships[0].entity.modality)}
              />
            </Link>
          </MobileCarouselWrapper>
        ) : (

          <CarouselContainer>
            <SliderWrapper hasOneItem={false}>
              <Slider {...settings}>
                {listChampionships.map((item) => {
                  const file = item.files.find(
                    (file) => file.business_type === "CHAMPIONSHIP_CARD"
                  );
                  const game = item.entity.game.files.find(
                    (file) => file.business_type === "GAMES_ICON"
                  );
                  return (
                    <MobileCarouselWrapper key={item.entity.id}>
                      <Link to={`/championship/${item.entity.id}`}>
                        <Card
                          key={item.entity.id}
                          championshipName={item.entity.name}
                          championshipImg={file?.url}
                          gameImg={game?.url}
                          date={new Date(item.entity.start_date)}
                          date_end={new Date(item.entity.end_date)}
                          currentUsers={item.entity.registered_participants_count}
                          maxUsers={item.entity.participant_limit}
                          squad={ChampionshipsTranslate(item.entity.modality)}
                        />
                      </Link>
                    </MobileCarouselWrapper>
                  );
                })}
              </Slider>
            </SliderWrapper>
          </CarouselContainer>
        )
      ) : (
        ""
      )}
    </S.CampeonatosContainer>
  );
};

export default ChampionshipsSession;
