import { BaseResponse, PaginationResponse } from "@interfaces/base";
import { progamersApi } from "@config/axios.config";
export default function eventService() {

  const getEventsPublic = async (): Promise<PaginationResponse<BaseResponse<any>>> => {
    const { data } = await progamersApi.get(`/public/events`, {
      params: {
        limit: 20,
      },
    });
    return data;
  };

  const getEventByURL = async (
    url?: string
  ): Promise<BaseResponse<any>> => {
    const { data } = await progamersApi.get(`/public/events/${url}`);
    return data;
  };

  return {
    getEventsPublic,
    getEventByURL,
  };
}