import ReactDOM from "react-dom/client";
import App from "./App";
import "react-tabs/style/react-tabs.css";
import "react-multi-carousel/lib/styles.css";
import "react-image-crop/dist/ReactCrop.css";
import "./components/template/global/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactGA from "react-ga";
import GlobalStyles from "./components/template/global/global";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: import.meta.env.VITE_SOME_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/progamers.com.br/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactGA.initialize("G-N3BP319ZW9");

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <GlobalStyles />
    <App />
  </>
);
