import { progamersApi } from "@config/axios.config";
import { PaginationResponse } from "@interfaces/base";
import { Notifications } from "@interfaces/notification.interface";

export default function notificationService() {
  const pathMarkAsRead = async (id: string): Promise<void> => {
    await progamersApi.patch(`/notifications/mark-as-read/${id}`);
  };

  const getAllNotification = async (
    page: number = 1,
    limit: number = 8
  ): Promise<PaginationResponse<Notifications>> => {
    const { data } = await progamersApi.get(
      `/notifications/all-platform?page=${page}&limit=${limit}`
    );
    return data;
  };

  const deleteNotificationById = async (id: string): Promise<void> => {
    await progamersApi.delete(`/notifications/${id}`);
  };

  const getAllNotificationUnred = async (): Promise<Notifications[]> => {
    const { data } = await progamersApi.get("/notifications");
    return data;
  };

  return {
    getAllNotification,
    pathMarkAsRead,
    deleteNotificationById,
    getAllNotificationUnred,
  };
}
