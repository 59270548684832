import styled from "styled-components";

import { device } from "../../../../utils";

export const CampeonatosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  color: white;
  z-index: 1;
  

  div.cardContainer {
    display: flex;
    flex-direction: row;
  }

  .carousel-container {
    width: 100%;
    height: 100%;
  }

  .carousel-item {
    margin: 0rem 1.5rem 0rem 1.5rem;
    width: 4rem;
  }

  .carousel {
    margin: 1.5rem 1rem 0rem 1.5rem;
  }
`;

export const DesktopCarouselWrapper = styled.div`
  display: none;

  @media ${device.desktopSmall} {
    display: flex;
  }
`;

export const MobileCarouselWrapper = styled.div`
  padding: 0 0.625rem;

  .slick-slide {
    opacity: 0.4;
    transform: scale(0.9);
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
  }

  .slick-active {
    opacity: 1;
    transform: scale(1);
  }
`;

export const CampeonatosTitle = styled.h2`
  text-align: center;
  font-size: 20pt;
  font-weight: bold;
  color: white;
  font-family: "Quinn", Poppins, sans-serif;
  text-transform: uppercase;
  padding-bottom: 3.125rem;
  margin-top: 3.375rem;

  @media ${device.desktopSmall} {
    font-size: 28pt;
  }
`;

export const CarouselContainer = styled.div`
  width: 1336px;
  margin: 0 auto;

    @media ${device.tabletSmall} {
        width: 100vw;
    }

    
`;

export const SliderWrapper = styled.div`
    ${(props) =>
            props.hasOneItem &&
            ` 
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
  `}
    .slick-dots {
        margin-top: 32px;
        display: flex !important;
        justify-content: center;
        gap: 1px;

        li {
            margin: 0;
            color: white;

            button {
                &:before {
                    font-size: 10px;
                    color: ${({ textColor }) => textColor || "#FFF"};
                }
            }
        }


        .slick-active button:before {
            color: ${({ textColor }) => textColor || "#FFF"};
        }
    }
`;
