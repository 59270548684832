import dayjs from "dayjs";
import ptBr from "dayjs/locale/pt-br";

dayjs.locale(ptBr);

export function dateFormat(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("D[ de ]MMMM[, ]YYYY");
}

export function dateFormat2(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("D[ de ]MMMM[, ]YYYY[,] HH:mm");
}

export function dateTimeFormat(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("D[ de ]MMMM[, ]YYYY[,] HH [horas e] mm [minutos]");
}

// TODO => Refatorar Nome
export function dateTimeFormat2(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("DD/MM/YY");
}

const calcularDiferencaDias = (data: Date) => {
  const dataAtual = new Date();
  const dataFornecida = new Date(data);
  const diferencaTempo = dataAtual.getTime() - dataFornecida.getTime();
  const diferencaDias = Math.floor(diferencaTempo / (1000 * 60 * 60 * 24));
  return diferencaDias;
};

export const formatarDataNotification = (data: Date) => {
  const diferencaDias = calcularDiferencaDias(data);

  if (diferencaDias <= 7) {
    if (diferencaDias === 0) return "hoje";
    return `há ${diferencaDias}d`;
  } else {
    const dataFornecida = new Date(data);
    const dia = String(dataFornecida.getDate()).padStart(2, "0");
    const mes = String(dataFornecida.getMonth() + 1).padStart(2, "0"); // Janeiro é 0
    const ano = dataFornecida.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }
};

export const formatDates = (start_date: string, end_date: string): string => {
  const options: Intl.DateTimeFormatOptions = { day: "numeric" };
  const start = new Date(start_date);
  const end = new Date(end_date);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) return "Datas inválidas";

  const days: string[] = [];
  let currentDate = new Date(start);

  while (currentDate <= end) {
    days.push(currentDate.toLocaleDateString("pt-BR", options));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const month = start
    .toLocaleDateString("pt-BR", { month: "long" })
    .toUpperCase();

  return `${days.join(", ")} ${month}`;
};

export const formatTime = (start_date: string) => {
  if (start_date) {
    const [year, month, day, hours, minutes, seconds] = start_date
      .replace("Z", "")
      .split(/[-T:.]/)
      .map(Number);

    const dateStart = new Date(year, month - 1, day, hours, minutes, seconds);

    return `${dateStart?.getHours().toString().padStart(2, "0")}:${dateStart.getMinutes().toString().padStart(2, "0")}`;
  }
};

export function findEarliestDateTime(events) {
  if (!Array.isArray(events) || events.length === 0) return null;
  const sortedEvents = [...events].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return a.start_time.localeCompare(b.start_time);
  });

  const firstEvent = sortedEvents[0];

  if (!firstEvent) return null;

  return `${firstEvent.date.split("T")[0]} ${firstEvent.start_time}`;
}