import { dateTimeFormat2 } from "@utils/date";
import * as S from "./styles";
import { Calendar, Swords, Users } from "lucide-react";

type Props = {
  championshipName: string;
  championshipImg?: string;
  gameImg?: string;
  date: Date;
  currentUsers: number;
  maxUsers: number;
  squad: string;
  primary: string;
  secondary: string;
  textColor: string;
};

const Card: React.FC<Props> = ({
  championshipName,
  championshipImg,
  gameImg,
  date,
  currentUsers,
  maxUsers,
  squad,
  primary,
  secondary,
  textColor
}) => {
  return (
    <S.CardContainer key={championshipName}>
      <S.ImageContainer>
        {gameImg && <S.CardGameImage src={gameImg} alt="Campeonato" />}
        <S.CardChampionshipImage src={championshipImg} alt="Campeonato" />
      </S.ImageContainer>
      <S.InfoContainer style={{ backgroundColor: `${primary}` }} textColor={textColor}>
        <S.CardTitle>{championshipName}</S.CardTitle>
        <S.CardFooterContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              <Calendar /> {dateTimeFormat2(date)}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              <Users /> {currentUsers}/{maxUsers}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              <Swords /> {squad}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
        </S.CardFooterContainer>
      </S.InfoContainer>
    </S.CardContainer>
  );
};

export default Card;
