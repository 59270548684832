import { Search } from "lucide-react";
import { useDisclosure } from "@chakra-ui/react";
import {
  ActionBox,
  BlurOverlay,
  Checkbox,
  CheckboxLabel,
  Input,
  Label,
  SearchBox,
  SearchInput,
  SearchSection,
} from "@pages/social/filter/styles.ts";
import {
  ActionButton,
  ActionText,
  CardList,
  NotFoundMessage,
  ResultSection,
} from "@pages/social/styles.ts";
import Modal from "@components/template/modal";
import CreateTeamModal from "@pages/social/filter/players/createTeamModal";
import Pagination from "@components/pagination";
import defaultbanner from "@assets/ativos/png/defaultbanner.png";

import { ChangeEvent, useState } from "react";
import useUserPublic from "@hooks/useUserPublic.ts";
import PlayerCard from "@components/playerCard";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";
import useUser from "@hooks/useUser.ts";
import { Link } from "react-router-dom";

const PlayersFilter: React.FC = () => {
  const { searchUsersPagination } = useUserPublic();
  const { userAuth } = useUser();

  const modalCreateTeam = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);
  const [isChecked, setIsChecked] = useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openModalCreateTeam = () => {
    modalCreateTeam.onOpen();
  };

  const closeModal = () => {
    modalCreateTeam.onClose();
  };

  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setInputValue(event.target.value);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const searchParams: ISearchPagination = {
    search: inputValue,
    page: currentPage,
    limit: 20,
    order: isChecked ? "asc" : "desc",
  };

  const { data } = searchUsersPagination(searchParams);

  return (
    <>
      <SearchSection>
        <SearchBox>
          <Label>Buscar por nome/nickname</Label>
          <SearchInput>
            <Input
              type="text"
              id="search-input-user"
              placeholder="Pesquisar "
              value={inputValue}
              onChange={handleInputChange}
            />
            <Search />
          </SearchInput>
          <div>
            <Checkbox
              onChange={handleCheckboxChange}
              type="checkbox"
              id="order-players"
              checked={isChecked}
            />
            <CheckboxLabel id={"order"} htmlFor="order-players">
              Ordem Alfabética
            </CheckboxLabel>
          </div>
        </SearchBox>

        <ActionBox>
          {!userAuth?.id && (
            <BlurOverlay>
              Para formar um time, é necessário estar logado.
              <Link to="/login">
                <ActionButton>Fazer login agora ?</ActionButton>
              </Link>
            </BlurOverlay>
          )}

          <ActionText>Quer formar um time?</ActionText>
          <ActionButton onClick={() => openModalCreateTeam()}>
            Forme já
          </ActionButton>
        </ActionBox>
      </SearchSection>

      <ResultSection>
        <>
          <CardList>
            {data && data.data && data.data.length > 0 ? (
              data.data.map((player) => {
                const profilePhoto = player.files?.find(
                  (file) => file.business_type === "USER_PROFILE_PHOTO"
                )?.url;
                const bannerPhoto = player.files?.find(
                  (file) => file.business_type === "USER_PROFILE_BANNER"
                )?.url;

                return (
                  <PlayerCard
                    id={player.entityId}
                    key={player.entityId}
                    name={player.entity.name}
                    nick={player.entity.nick}
                    avatar={profilePhoto || null}
                    backgroundImage={bannerPhoto || defaultbanner}
                  />
                );
              })
            ) : (
              <NotFoundMessage>
                Jogadores não encontradas para este filtro
              </NotFoundMessage>
            )}
          </CardList>
        </>

        <Pagination
          currentPage={data?.page}
          totalPages={data?.totalPage}
          onPageChange={handlePageChange}
        />
      </ResultSection>

      <Modal
        title=""
        bgColor={"#0F1B34"}
        size={"sm"}
        isOpen={modalCreateTeam.isOpen}
        handlerCancel={closeModal}
        isFooter={false}
      >
        <CreateTeamModal />
      </Modal>
    </>
  );
};

export default PlayersFilter;
