import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  INotificationInvite,
  Notifications,
  NotificationsType,
} from "@interfaces/notification.interface";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import NotificationInvitation from "./NotificationInvitation";
import useNotification from "@hooks/useNotification";
import { MdFiberNew } from "react-icons/md";
import { BsPersonAdd } from "react-icons/bs";
import Modal from "@components/template/modal";
import { BsPersonCheck } from "react-icons/bs";
import { BsPersonSlash } from "react-icons/bs";
import { formatarDataNotification } from "@utils/date";

type Props = {
  notification: Notifications;
  refA?: React.LegacyRef<HTMLDivElement>;
};

const NotificationItem: React.FC<Props> = ({ notification, refA }) => {
  const { markAsRead, deleteNotification } = useNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onExpanded = async () => {
    if (notification.isRead) return;
    await markAsRead(notification.id);
  };

  const NotificationIcon = (type: NotificationsType) => {
    return {
      TEAM_INVITE: <BsPersonAdd size={26} />,
      TEAM_JOIN: <BsPersonCheck size={26} />,
      TEAM_LEAVE: "TEAM_LEAVE",
      TEAM_KICK: "TEAM_KICK",
      TEAM_UPDATE: "TEAM_UPDATE",
      TEAM_DELETE: "TEAM_DELETE",
      CHAMPIONSHIP_INVITE: "CHAMPIONSHIP_INVITE",
      CHAMPIONSHIP_CANCEL: "CHAMPIONSHI:CANCEL",
      CHAMPIONSHIP_IS_CLOSE: "CHAMPIONSHIP_IS_CLOSE",
      CHAMPIONSHIP_START: "CHAMPIONSHIP_START",
      CHAMPIONSHIP_REGISTER_ACCEPT: "CHAMPIONSHIP_REGISTER_ACCEPT",
      CHAMPIONSHIP_REGISTER_REJECT: "CHAMPIONSHIP_REGISTER_REJECT",
      CHAMPIONSHIP_RESULT: "CHAMPIONSHIP_RESULT",
      CHAMPIONSHIP_MATCH_RESULT: "CHAMPIONSHIP_MATCH_RESULT",
      CHAMPIONSHIP_MATCH_CANCEL: "CHAMPIONSHIP_MATCH_CANCEL",
      CHAMPIONSHIP_MATCH_RESCHEDULE: "CHAMPIONSHIP_MATCH_RESCHEDULE",
      CHAMPIONSHIP_MATCH_UPDATE: "CHAMPIONSHIP_MATCH_UPDATE",
      CHAMPIONSHIP_MATCH_CODE: "CHAMPIONSHIP_MATCH_CODE",
      CHAMPIONSHIP_MATCH_IS_CLOSE: "CHAMPIONSHIP_MATCH_IS_CLOSE",
      CHAMPIONSHIP_MATCH_IS_CLOSE_TIME_15:
        "CHAMPIONSHIP_MATCH_IS_CLOSE_TIME_15",
      USER_ALTER_PASSWORD: "USER_ALTER_PASSWORD",
      BADGET_RECEIVE: "BADGET_RECEIVE",
      EVENT_ANNOUNCE: "EVENT_ANNOUNCE",
      ANNOUNCE_NEW: <MdFiberNew size={26} />,
      REFUSED: <BsPersonSlash size={26} />,
    }[type];
  };

  const teamsInvitation = [
    NotificationsType.TEAM_INVITE,
    NotificationsType.TEAM_JOIN,
    NotificationsType.REFUSED,
  ];

  return (
    <>
      <Accordion allowMultiple ref={refA}>
        <AccordionItem border={"none"}>
          <Flex bg={"#1E1E1E"} h={"50px"}>
            <AccordionButton gap={"6px"} onClick={onExpanded}>
              {!notification.isRead && (
                <Box
                  w={"8px"}
                  h={"8px"}
                  bg={"#e94560"}
                  borderRadius={"100%"}
                  border={"1px solid #ffffff"}
                />
              )}
              {NotificationIcon(notification.type)}
              <Box as="span" flex="1" textAlign="left">
                {notification.title}
              </Box>
              <Text fontSize={"13px"} fontWeight={"bold"}>
                {formatarDataNotification(notification.createAt)}
              </Text>
            </AccordionButton>
            <Menu>
              <MenuButton
                border={"none"}
                alignSelf={"center"}
                as={IconButton}
                aria-label="Options"
                _active={{ backgroundColor: "none" }}
                _hover={{ backgroundColor: "none" }}
                icon={
                  <HiOutlineDotsHorizontal
                    fontSize={"30px"}
                    cursor={"pointer"}
                    color={"#ffffff"}
                  />
                }
                variant="outline"
              />
              <MenuList backgroundColor={"#0C1212"} borderColor={"#E94560"}>
                <MenuItem
                  backgroundColor={"#0C1212"}
                  onClick={() => deleteNotification(notification.id)}
                >
                  Excluir
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <AccordionPanel pb={4}>
            {notification.type === NotificationsType.ANNOUNCE_NEW &&
              Array.isArray(notification.body) && (
                <UnorderedList onClick={onOpen} h={"134px"} overflow={"hidden"}>
                  {notification.body.map((item) => (
                    <ListItem
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      listStyleType={"disc"}
                    >
                      {item}
                    </ListItem>
                  ))}
                </UnorderedList>
              )}
            {teamsInvitation.includes(notification.type) && (
              <NotificationInvitation
                invite={notification.body as INotificationInvite}
                type={notification.type}
              />
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Modal
        isOpen={isOpen}
        title="Novidades"
        handlerCancel={onClose}
        isHeader={false}
        isFooter={false}
      >
        <Flex
          bgColor={"#1E1E1E"}
          pt={"12px"}
          pb={"12px"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"4px"}
          boxSizing={"border-box"}
          w={"calc(100% + 3rem)"}
          position={"relative"}
          left={"-1.5rem"}
          top={"-0.5rem"}
          zIndex={1}
          borderTopStartRadius={"12px"}
          borderTopRightRadius={"12px"}
        >
          <MdFiberNew size={26} /> Novidades
        </Flex>
        {Array.isArray(notification.body) && (
          <UnorderedList>
            {notification.body.map((item) => (
              <ListItem>{item}</ListItem>
            ))}
          </UnorderedList>
        )}
      </Modal>
    </>
  );
};

export default NotificationItem;
