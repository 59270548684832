import CreateTeam from "@pages/createTeam";
import HomePage from "@pages/homePage";
import Login from "@pages/login";
import Profile from "@pages/profile";
import Register from "@pages/register";
import Team from "@pages/team";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { ChampionshipDetails } from "@pages/championshipDetails";
import ResetPasswordPage from "@pages/ResetPassword";
import EventLcg from "@pages/eventLcg";
import Social from "@pages/social";
import EventPage from "@pages/event";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/change-password" element={<ResetPasswordPage />} />
        {/* <Route path="/auth/steam/callback" element={<CallbackSteam />} />
        <Route path="/championships" element={<Championships />} /> */}
        <Route
          path="/championship/:championshipId/eventlcg"
          element={<EventLcg />}
        />

        <Route
          path="/championship/:championshipId"
          element={<ChampionshipDetails />}
        />

        {/* <Route path="/championship/:id/teams" element={<TimesCampeonato />} /> */}
        <Route path="/profile/:userId" element={<Profile />} />
        <Route path="/social" element={<Social />} />
        {/* <Route path="/news" element={<News />} /> */}
        <Route path="/team/:teamId" element={<Team />} />
        <Route path="/:category/:eventName" element={<EventPage />} />
        {/* <Route path="/quem-somos" element={<QuemSomos />} /> */}
        {/* <Route path="/match/:id" element={<Match />} /> */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="team/create" element={<CreateTeam />} />
        </Route>
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
