import useUser from "@hooks/useUser";
import { ReactNode, createContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";

type TSocketContext = {
  socketManager: Socket | null;
};
type TSocketContextProvider = {
  children: ReactNode;
};

export const SocketContext = createContext({} as TSocketContext);

export const SocketContextProvider: React.FC<TSocketContextProvider> = ({
  children,
}) => {
  const [socketManager, setSocketManager] = useState<Socket | null>(null);
  const { userAuth } = useUser();

  useEffect(() => {
    if (!userAuth) return;
    if (socketManager) return;
    setSocketManager(
      io(`${import.meta.env.VITE_SOME_API_URL}`, {
        query: { userId: userAuth.id },
        transports: ["websocket"],
      })
    );
  }, [userAuth]);

  return (
    <SocketContext.Provider value={{ socketManager }}>
      {children}
    </SocketContext.Provider>
  );
};
