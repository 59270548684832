import { useQuery } from "@tanstack/react-query";
import {
  optionsFetchSearchTeamByNameAndTag,
  optionsFetchTeamByIdPublic,
  optionsFetchTeamMembersByIdPublic,
} from "@plugins/reactQuery/queryOptions.reactquery";
import { FileBussinessType } from "@interfaces/file.interface";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";

const useTeamPublic = (teamId?: string) => {
  const { data: team } = useQuery(optionsFetchTeamByIdPublic(teamId!));

  const files = team?.files || [];
  const avatarFile = files.find(
    (item) => item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO
  );

  const bannerFile = files.find(
    (item) => item.business_type === FileBussinessType.TEAM_BANNER_PHOTO
  );

  const { data: teamMembers } = useQuery(
    optionsFetchTeamMembersByIdPublic(teamId!)
  );


  const searchTeamByNameAndTag = (searchPagination: ISearchPagination) => {
    ("searchTeamByNameAndTag", searchPagination);
    const { data: data } = useQuery(optionsFetchSearchTeamByNameAndTag(searchPagination));
    return { data };
  };

  return {
    team: team?.entity,
    teamFiles: team?.files,
    avatarFile,
    bannerFile,
    teamMembers,
    searchTeamByNameAndTag
  };
};

export default useTeamPublic;
