import {
  optionsFetchEventPagePublic,
  optionsFetchEventsPublic,
} from "@plugins/reactQuery/event.query-options";
import { useQuery } from "@tanstack/react-query";

const useEvent = () => {
  const { data: getListEvents } = useQuery(optionsFetchEventsPublic());

  const getEventByURL = (url: string) => {
    const { data, isLoading } = useQuery(optionsFetchEventPagePublic(url));

    return { data, isLoading };
  };

  const listEvents = getListEvents;

  return {
    listEvents,
    getEventByURL,
  };
};

export default useEvent;
