import { ChampionshipsTournament } from "@interfaces/championships.interface";
import { FileBussinessType } from "@interfaces/file.interface";
import {
  optionsFetchChampionshipByIdPublic,
  optionsFetchChampionshipParticipantsByIdPublic,
  optionsFetchChampionshipsPublic,
  optionsFetchChampionshipsTournamentPublic,
} from "@plugins/reactQuery/championships.query-options";
import championshipService from "@services/championships.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useChampionship = (id?: string) => {
  const queryClient = useQueryClient();

  const { data: getListChampionships } = useQuery(
    optionsFetchChampionshipsPublic()
  );
  const { postRegisterTeamChampionship, postRegisterUserChampionship } =
    championshipService();

  const { data: getChampionshipByIdPublic } = useQuery(
    optionsFetchChampionshipByIdPublic(id!)
  );

  const listChampionships = getListChampionships?.data.filter(
    (item) => item.entity.status !== "CANCELLED"
  );

  const listChampionshipsFilterById = (ids: string[]) =>
    getListChampionships?.data?.filter((item) => ids?.includes(item?.entityId)) || [];

  const championship = getChampionshipByIdPublic;

  const registerUserChampionship = async (championshipId: string) => {
    await postRegisterUserChampionship(championshipId);
    queryClient.invalidateQueries({
      queryKey: [optionsFetchChampionshipByIdPublic(championshipId)],
    });
    toast.success("Registro feito com sucesso");
  };

  const registerTeamChampionship = async (
    championshipId: string,
    teamId: string
  ) => {
    await postRegisterTeamChampionship(championshipId, teamId);
    queryClient.invalidateQueries({
      queryKey: [optionsFetchChampionshipByIdPublic(championshipId)],
    });
    toast.success("Registro feito com sucesso");
  };

  const { data: participantsChampionship, isLoading } = useQuery(
    optionsFetchChampionshipParticipantsByIdPublic(id!)
  );

  const { data: fetchChampionshipsTournament, isLoading: isLoadingTournament } =
    useQuery(optionsFetchChampionshipsTournamentPublic(id!));

  const wining =
    fetchChampionshipsTournament?.[0]?.championship_round?.map((item) => {
      return {
        title: item.name,
        seeds: item.championship_match.map((item) => {
          const imag1 = item.data_participants?.[0]?.files?.find(
            (item) =>
              item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO ||
              item.business_type === FileBussinessType.USER_PROFILE_PHOTO
          );
          const imag2 = item.data_participants?.[1]?.files?.find(
            (item) =>
              item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO ||
              item.business_type === FileBussinessType.USER_PROFILE_PHOTO
          );

          return {
            id: item.id,
            date: item.start_date || "",
            status: item.status,
            teams: [
              {
                id: item.participant_opponent1,
                name: item?.data_participants?.[0]?.entity.name,
                score: item.score_opponent1,
                result: item.result_opponent1,
                imageUrl:
                  imag1?.url ||
                  "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg",
              },
              {
                id: item.participant_opponent2,
                name: item?.data_participants?.[1]?.entity.name,
                score: item.score_opponent2,
                result: item.result_opponent2,
                imageUrl:
                  imag2?.url ||
                  "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg",
              },
            ],
          };
        }),
      };
    }) || [];

  const lower =
    fetchChampionshipsTournament?.[1]?.championship_round?.map((item) => {
      return {
        title: item.name,
        seeds: item.championship_match.map((item) => {
          const imag1 = item.data_participants?.[0]?.files?.find(
            (item) =>
              item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO ||
              item.business_type === FileBussinessType.USER_PROFILE_PHOTO
          );
          const imag2 = item.data_participants?.[1]?.files?.find(
            (item) =>
              item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO ||
              item.business_type === FileBussinessType.USER_PROFILE_PHOTO
          );
          return {
            id: item.id,
            date: item.start_date || "",
            status: item.status,
            teams: [
              {
                id: item.participant_opponent1,
                name: item?.data_participants?.[0]?.entity.name,
                score: item.score_opponent1,
                result: item.result_opponent1,
                imageUrl:
                  imag1?.url ||
                  "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg",
              },
              {
                id: item.participant_opponent2,
                name: item?.data_participants?.[1]?.entity.name,
                score: item.score_opponent2,
                result: item.result_opponent2,
                imageUrl:
                  imag2?.url ||
                  "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg",
              },
            ],
          };
        }),
      };
    }) || [];

  const championshipsTournament: ChampionshipsTournament = { wining, lower };

  return {
    listChampionships,
    listChampionshipsFilterById,
    championship,
    participantsChampionship,
    registerTeamChampionship,
    registerUserChampionship,
    isLoadingParticipantChampionship: isLoading,
    isLoadingTournament,
    championshipsTournament,
  };
};
export default useChampionship;
