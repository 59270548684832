import { device } from "@utils/index";
import styled from "styled-components";

export const RodapeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    width: 100%;

  @media ${device.desktopSmall} {
    align-items: center; 
    padding: 5rem 0;
  }

    @media ${device.mobileSmall} {
        flex-direction: column!important;
    }

    @media ${device.mobileMedium} {
        flex-direction: column!important;
    }
    

`;

export const RodapeMainContent = styled.div`
  display: flex;
    flex-direction: row;
  align-items: center;
  align-items: flex-start;

  @media ${device.tablet} { 
    flex-direction: column;
  } 

`;

export const RodapeLinkMenusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
    width: 100%;
    @media ${device.mobileSmall} { 
        flex-direction: column!important;
    }

    @media ${device.mobileMedium} { 
        flex-direction: column!important;
    }


    > div {
    width: 200px;
  }

  @media ${device.desktopSmall} {
    flex-direction: column;
  }

  @media ${device.desktop} {
    flex-direction: row;
  }

  @media ${device.desktopLarge} {
    flex-direction: row;
  }

    @media ${device.tabletSmall} {
        flex-direction: column;
    }
`;

export const RodapeCopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;
`;

export const RodapeCopyright = styled.p`
  font-size: 10pt;
  text-align: center;
  color: var(--text-color);
  text-decoration: none;


    @media ${device.tabletSmall} {
        font-size: 5pt;
    }
`;
