import { Body, Card, Header, Title } from "@components/SectionCard/styled.ts";

const SectionCard = ({
  backgroundImage,
  name,
  description,
  primary,
  secondary,
  index,
  textColor,
}) => {
  return (
    <Card
      borderColor={textColor}
      bgColor={primary}
      secondary={textColor}
      reverse={index % 2 !== 0}
    >
      {backgroundImage && (
        <Header
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        ></Header>
      )}
      <Body textColor={textColor}>
        <Title textColor={textColor}>{name}</Title>
        <div
          style={{ marginLeft: "10px" }}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </Body>
    </Card>
  );
};

export default SectionCard;
