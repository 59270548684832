import styled from "styled-components";
import { device, statusColors } from "@utils/index.ts";

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 2rem;
    display: flex;
    background: #0f1b34;
    overflow: hidden;
    border-radius: 8px;

    @media ${device.tabletSmall} {
        flex-direction: column;
    }

    @media ${device.tablet} {
        flex-direction: column;
    }

    @media ${device.mobileMedium} {
        flex-direction: column;
        width: 85%;  
    }

    @media ${device.tabletSmall} {
        flex-direction: column;
    }
    @media ${device.notebook} {
        flex-direction: column;
    }

    @media ${device.laptop} {
        flex-direction: column;

    }

`;

export const Header = styled.div`
    padding: 2rem;
    border-radius: 8px;
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;
    margin-top:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    min-width: 25%;
    height: auto;
 
    @media ${device.laptop} {
        width: 100%;
        height: 315px;
    }
`;
export const ImageView = styled.img`
    margin-top: 2rem;
    border-radius: 10px;  
`;

export const Body = styled.div`
  
    padding: 10px;
    font-size: 1.25rem;
`;


export const Title = styled.h3`
  padding: 10px;
  font-weight: bold;
  font-size: 2rem;
  color: #e94560;
    @media ${device.laptop} {
        font-size: 2rem; 
    }
    @media ${device.tabletSmall} {
        font-size: 1.8rem;
    }
    
`;
export const DescriptionText = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .decription-html {
        margin-bottom: 2rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    @media ${device.tabletSmall} {
        font-size: 1rem;
    }

    @media ${device.laptop} {
        font-size: 1rem;
    }
`;
  
export const EventInfo = styled.div`
    display: flex; 
    flex-direction: column;
    margin-left: 1rem;
    gap:1rem;
    .info-box{
        gap:1rem;
        display: flex;
        flex-direction: row;
    }
    margin-bottom: 2rem;
`;


export const ActionButton = styled.button`
    background-color: #e63946;  
    padding: 1rem;
    font-weight: bold; 
    font-size: 1rem; 
    justify-content:space-between; 
    border-radius: 2rem;
    width: 200px;
    margin-left:1rem;
    
    @media ${device.laptop} {
       font-size: 1rem;
        margin-left:0;
        width: 100%;
    }
`;


interface StatusProps {
    status: string;
  }
  
  export const StatusEvent = styled.div<StatusProps>`
    padding: 4px 8px 4px 8px;
    border: 2px solid ${({ status }) => statusColors[status].color};
    color: ${({ status }) => statusColors[status].color};
    font-weight: 600;
    text-align: center;
    font-size: small;
    border-radius: 8px;
    max-width: 128px;
    width: auto;
    margin-top: 0.5rem;
  `;
