import ChampionshipsSession from "./components/championshipsSession";
import Welcome from "./components/welcome";
import Layout from "@components/template/layout";
import TwitchEmbedCard from "@components/twitchEmbedCard";
import EventArea from "./components/eventArea";
import { Helmet } from "react-helmet-async";

const HomePage = () => {

  

  return (
    <>
      <Helmet>
        <title>Home - Progamers</title>
        <meta property="og:title" content="Home - Progamers" />
      </Helmet>
      <Layout>
        <Welcome />
        <ChampionshipsSession titleSection={"Campeonatos"} />
        <EventArea />
        <TwitchEmbedCard />
      </Layout>
    </>
  );
};

export default HomePage;
