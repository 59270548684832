import React from "react";
import {
  Container,
  DateSection,
  Day,
  GameSection,
  IconBackground,
  Section,
  Time,
  Title,
} from "./style";
import { Ticket } from "lucide-react";

const ScheduleItem = ({ schedule, primary, secondary, textColor }) => {
  const date = new Date(schedule.date);

  return (
    <Container
      bgColor={primary}
      secondary={secondary}
      borderColor={primary}
      textColor={textColor}
    >
      <DateSection borderColor={secondary} bgColor={primary}>
        <Day textColor={textColor}>{date.getUTCDate()}</Day>
        <div textColor={textColor} className="month">
          {date.toLocaleDateString("pt-BR", { month: "long" }).toUpperCase()}
        </div>
      </DateSection>
      <Section>
        <span>Horário</span>
        <Time>
          {schedule.start_time} - {schedule.end_time}
        </Time>
      </Section>
      <Section>
        <span>{schedule.name}</span>
        <Time>{schedule.activity_type}</Time>
      </Section>
    </Container>
  );
};

export default ScheduleItem;
