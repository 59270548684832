import styled from "styled-components";
import { device } from "@utils/index";

export const LogoProGamers = styled.img`
  width: 60%;
  margin: 2rem auto 0;
`;

export const Slung = styled.h1`
    font-weight: bold;
    text-align: center;
    font-size: 4rem;

    @media ${device.tablet} {
        font-size: 2rem;
    }

    @media ${device.mobileLarge} {
        font-size: 1rem;
    }
`;
export const Hastag = styled.h1`
  font-weight: bold;
  font-size: 2rem;

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.mobileLarge} {
    font-size: 16px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 4rem;

  @media ${device.tablet} {
    max-width: 350px;
  }

  @media ${device.mobileLarge} {
    max-width: 250px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 1.5rem;
  color: var(--white);
  font-size: 40px;
  font-weight: 600;

  @media ${device.tablet} {
    margin-bottom: 2rem;
    font-size: 30px;
  }

  @media ${device.mobileLarge} {
    font-size: 24px;
  }
`;

export const ProGamers = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 700px;
    width: 100vw;
    justify-content: center; 

    @media ${device.tablet} {
        height: 600px; 
    }

    @media ${device.mobileLarge} {
        height: 500px; 
    }
`;

export const TabButton = styled.button`
  background-color: var(--layout-color);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--button-hover-color);
  }
`;

export const ActionText = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    @media ${device.tablet} {
        font-size: 12px;
    }

    @media ${device.mobileLarge} {
        font-size: 12px;
    }
`;

export const ActionButton = styled.button`
  background-color:var(--layout-color);
  color: var(--white);
  border: none;
    border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
      background-color: var(--button-hover-color);
  }
`;

export const ResultSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
    padding: 0;
    margin-top: 5rem;
`;

export const NotFoundMessage = styled.h3`
  font-size: 1.3rem;
`;

export const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 4rem;
    width: 100%; 
`;

export const Announcement = styled.div`
  margin-top: 8.5rem;
  gap: 2rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
 
`;
export const AnnouncementBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  padding: 50px;
  justify-content: center;
    
    
`;

export const AnnouncementSubtitle = styled.h2`
  font-weight: bold;
  font-size: 8rem;

    @media ${device.tablet} {
        font-size: 1rem;
    }

    @media ${device.mobileLarge} {
        font-size: 1.1rem;
    }
  
`;
export const AnnouncementTitle = styled.h1`
  font-weight: bold;
  font-size: 2rem;

    @media ${device.tablet} {
        font-size: 1.1rem;
    }

    @media ${device.mobileLarge} {
        font-size: 1.1rem;
    }
`;
