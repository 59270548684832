import logoHorizontal from "@assets/ativos/png/logohorizontal.png";

import { Hastag, LogoProGamers, ProGamers } from "@pages/social/styles";

const Welcome = () => {
  return (
    <ProGamers
      style={{
        backgroundColor: '#e94560',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >

      <LogoProGamers src={logoHorizontal} />

      <Hastag>#VEMSERPRO</Hastag>
    </ProGamers>
  );
};

export default Welcome;
