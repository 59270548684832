import notificationService from "@services/notification.service";
import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";

const notificationQueryOptions = () => {
  const { getAllNotification, getAllNotificationUnred } = notificationService();

  const optionsFetchInfinitNotifications = () => {
    return infiniteQueryOptions({
      queryKey: ["notificationAll"],
      queryFn: async ({ pageParam }) => {
        const result = await getAllNotification(pageParam);
        return result;
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        return lastPage.totalPage !== lastPage.page
          ? lastPage.page + 1
          : undefined;
      },
    });
  };

  const optionsFetchAllNotificationUnred = (isAuthenticated: boolean) =>
    queryOptions({
      queryKey: ["notificationAllUnred"],
      queryFn: getAllNotificationUnred,
      enabled: isAuthenticated,
    });

  return {
    optionsFetchInfinitNotifications,
    optionsFetchAllNotificationUnred,
  };
};

export const {
  optionsFetchInfinitNotifications,
  optionsFetchAllNotificationUnred,
} = notificationQueryOptions();
