import { Box, Image, Text } from "@chakra-ui/react";
import { FC } from "react";
import { Gamepad2 } from "lucide-react";

interface GamePillItemProps {
  label: string;
  icon: string | null;
  isSelected: boolean;
  onClick: () => void;
}

const GamePillItem: FC<GamePillItemProps> = ({
                                               label,
                                               icon,
                                               isSelected,
                                               onClick,
                                             }) => {
  return (
    <Box
      onClick={onClick}
      cursor="pointer"
      bg={"#090F1D"}
      borderRadius={"3px"}
      border={""}
      borderBottom={isSelected ? "0.2rem solid #E94560" : "0.2rem solid transparent"}
      color="white"
      p={5}
      display="flex"
      alignItems="center"
      gap={2}
      height="36px"
      transition="all 0.3s ease"
    >
      {icon ? (
        <Image src={icon} alt={label} boxSize={5} objectFit="contain" />
      ) : (
        <Gamepad2 size="20" />
      )}
      <Text fontSize="sm">{label}</Text>
    </Box>
  );
};

export default GamePillItem;
