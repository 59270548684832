import styled from "styled-components";
import { device } from "@utils/index.ts";

export const Card = styled.div`

    width: 384px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: var(--card-background-color);
    box-sizing: border-box;
    border: 1px solid transparent;
    transition: border-color 0.3s ease;
    border-radius: 5px;
    @media ${device.tablet} {
        width: 100%;
    }

    @media ${device.mobileLarge} {
        width: 100%;
    }
    @media ${device.notebook} {
        width: 355px;
    }

    &:hover {
        border-color: var(--layout-color);
        cursor: pointer;
    }
`;

export const Header = styled.div`
  position: relative;
  height: 150px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

export const Avatar = styled.img`
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const AvatarTeamPlaceholder = styled.div`
  position: absolute;
  bottom: 35px;
    background-color: var(--card-background-color);
  left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

export const Body = styled.div`
    text-align: center;
    height: 105px;
    padding-bottom: 20px;
    align-content: center;
`;

export const Title = styled.h3`
  padding: 10px;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--white);
`;

export const AvatarsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    background-color: var(--card-body-background-color);
    justify-content: center;
    gap: 8px;
`;

export const SmallAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--layout-color);
`;

export const TeamMembersCount = styled.div`
  font-weight: bold;
  display: flex;
  font-size: 0.9rem;
  gap: 5px;
  padding: 10px;
`;

export const SmallAvatarPlaceholder = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--card-background-color);
    display: flex;
    align-items: center;
    justify-content: center;  
    border: 0.1rem solid var(--layout-color);
`;
