import styled from "styled-components";
import { device } from "@utils/index.ts";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    max-height: 564px;
    height: 100%;
    color: #ffffff;
    transition-duration: 1s;
    cursor: pointer;

    @media ${device.tabletSmall} {
        max-width: 320px;
    }

    @media ${device.tablet} {
        max-width: 380px;
    }

    @media ${device.tabletLarge} {
        max-width: 400px;
    }
    @media ${device.notebook} {
        max-width: 430px;
    }


    &:hover {
        transition-duration: 0.8s;
        transform: scale(0.9);
    }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const CardChampionshipImage = styled.img`
    width: 100%;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
`;

export const CardGameImage = styled.img`
  position: absolute;
 
  width: 3.125rem;
  margin: 1rem;
  border: 0.125rem solid #fff;
`;

export const DetailsContainer = styled.div`
    font-size: var(--font-size-medium);
    position: absolute;
    bottom: 0;
    z-index: 996;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
    background-color: rgba(31, 43, 68, 0.8);

    strong {
        font-size: var(--font-size-medium);
        text-align: center;
    }

    @media ${device.tabletSmall} {
        strong {
            font-size: 1rem;
            text-align: center;
        }
    }

`;

export const InfoContainer = styled.div`
    display: flex;
    color: ${({ textColor }) => textColor || "#FFF"};
    flex-direction: column;
    background-color: transparent;
    min-height: 164px;
    height: auto; 
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const CardTitle = styled.p`
    height: 3.75rem;
    font-size: var(--font-size-large);
    padding: 1rem;

    @media ${device.tabletSmall} {
        font-size: 1rem;
    }
`;

export const CardFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
`;

export const CardSmallInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;
`;

export const CardSmallInfoText = styled.span`
  font-size: var(--font-size-base);
    @media ${device.tabletSmall} {

        font-size: 1rem;

    }
    
`;
