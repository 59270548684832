import userService from "@services/user.service";
import { queryOptions } from "@tanstack/react-query";
import { ISearchPagination } from "@interfaces/searchPagination.interface.ts";

const userQueryOptions = () => {
  const {
    getTeamsUserById,
    getSearchUser,
    getUserByIdPublic,
    getSetupByUserIdPublic,
    getUsersSearchPagination,
  } = userService();

  const optionsFetchTeamsUserById = (id: string) => {
    return queryOptions({
      queryKey: [],
      queryFn: () => getTeamsUserById(id),
      enabled: !!id,
    });
  };

  const optionsFetchSearchUser = (name: string) => {
    return queryOptions({
      queryKey: ["search_user", name],
      queryFn: () => getSearchUser(name),
      enabled: name.length > 3,
    });
  };

  const optionsFetchUserByIdPublic = (id: string) => {
    return queryOptions({
      queryKey: ["getUserByIdPublic", id],
      queryFn: () => getUserByIdPublic(id),
      enabled: !!id,
    });
  };

  const optionsFetchSetupUserByIdPublic = (id: string) =>
    queryOptions({
      queryKey: ["getSetupUserByIdPublic", id],
      queryFn: () => getSetupByUserIdPublic(id),
      enabled: !!id,
    });

  const optionsFetchSearchUserPagination = (searchPagination: ISearchPagination
  ) =>
    queryOptions({
      queryKey: ["getUsersSearchPagination", searchPagination],
      queryFn: async () => {
        const response = await getUsersSearchPagination(searchPagination);

        if (response && response.data && response.data.data) {
          return {
            data: response.data.data,
            page: response.data.page,
            limit: response.data.limit,
            total: response.data.total,
            totalPage: response.data.totalPage,
          };
        }

        return { data: [], page: 0, limit: 0 };
      },
    });

  return {
    optionsFetchTeamsUserById,
    optionsFetchSearchUser,
    optionsFetchUserByIdPublic,
    optionsFetchSetupUserByIdPublic,
    optionsFetchSearchUserPagination,
  };
};

export const {
  optionsFetchTeamsUserById,
  optionsFetchSearchUser,
  optionsFetchUserByIdPublic,
  optionsFetchSetupUserByIdPublic,
  optionsFetchSearchUserPagination,
} = userQueryOptions();
