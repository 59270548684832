import { dateTimeFormat2 } from "@utils/date";
import * as S from "./styles";
import { Calendar, Swords, Users } from "lucide-react";

type Props = {
  championshipName: string;
  championshipImg?: string;
  gameImg?: string;
  date: Date;
  date_end: Date;
  currentUsers: number;
  maxUsers: number;
  squad: string;
};

const Card: React.FC<Props> = ({
  championshipName,
  championshipImg,
  gameImg,
  date,
  date_end,
  currentUsers,
  maxUsers,
  squad,
}) => {
  return (
    <S.CardContainer key={championshipName}>
      <S.ImageContainer>
        {gameImg && <S.CardGameImage src={gameImg} alt="Campeonato" />}
        <S.CardChampionshipImage src={championshipImg} alt="Campeonato" />
        {/* <S.DetailsContainer>
          🕒 Inscrições encerram em <strong>10 dias</strong>
        </S.DetailsContainer> */}
      </S.ImageContainer>
      <S.InfoContainer>
        <S.CardTitle>{championshipName}</S.CardTitle>
        <S.CardFooterContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              <Calendar /> {dateTimeFormat2(date)}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              <Users /> {currentUsers}/{maxUsers}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              <Swords /> {squad}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>         
        </S.CardFooterContainer>
        <S.containerDiv>
          <S.StatusDiv status={date > new Date() ? "EM BREVE" : new Date() <= date_end && date <= new Date() ? "EM ANDAMENTO" : "ENCERRADO"}>
            {date > new Date() ? "EM BREVE" : new Date() <= date_end && date <= new Date() ? "EM ANDAMENTO" :  "ENCERRADO"}
          </S.StatusDiv>
        </S.containerDiv>
      </S.InfoContainer>
    </S.CardContainer>
  );
};

export default Card;
