import styled from "styled-components";

export const PageButton = styled.button`
  background-color: ${(props) => (props.active ? "#e94560" : "#0F1B34")};
  color: ${(props) => (props.active ? "white" : "white")};
  border: 1px solid #0f1b34;
  font-weight: bold;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
    border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #e94560;
    color: white;
  }

  &:disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
  }
`;

export const ActionButton = styled.button`
  border-radius: 4px;
  color: ${(props) => (props.active ? "white" : "white")};
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; 
 
`;
