import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Body,
  Card,
  Header,
  Nick,
  SmallAvatarPlaceholder,
  Title,
} from "@components/playerCard/styled.ts";
import { UserRound } from "lucide-react";

const PlayerCard = ({ backgroundImage, avatar, name, nick, id }) => {
  return (
    <Link to={`/profile/${id}`}>
      <Card>
        <Header
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        >
          {avatar ? (
            <Avatar src={avatar} alt="Avatar" />
          ) : (
            <SmallAvatarPlaceholder>
              <UserRound size="65" />
            </SmallAvatarPlaceholder>
          )}
        </Header>
        <Body>
          <Title>{name}</Title>
          <Nick>nick: {nick}</Nick>
        </Body>
      </Card>
    </Link>
  );
};

export default PlayerCard;
