import gameService from "@services/game.service";
import teamService from "@services/team.service";
import { queryOptions } from "@tanstack/react-query";

const optionsQuery = () => {
  const { getGameById, getAllGames, getAllGamesPublic } = gameService();
  const {
    getProfileTeam,
    getInviteNotificationsUser,
    getTeamMembers,
    getTeamByIdPublic,
    getTeamMembersByIdPublic,
    searchTeamByNameAndTag
  } = teamService();

  const optionsFetchGameId = (id: string) => {
    return queryOptions({
      queryKey: ["gameById", id],
      queryFn: () => getGameById(id),
      enabled: !!id,
    });
  };

  const optionsFetchGames = () => {
    return queryOptions({ queryKey: ["allGames"], queryFn: getAllGames });
  };

  const optionsFetchGamesPublic = () => {
    return queryOptions({ queryKey: ["allGamesPublic"], queryFn: getAllGamesPublic });
  };

  const optionsFetchTeamMembers = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamMembers", teamId],
      queryFn: () => getTeamMembers(teamId),
      enabled: !!teamId,
    });
  };


  const optionsFetchSearchTeamByNameAndTag = (
    searchPagination: ISearchPagination
  ) =>
    queryOptions({
      queryKey: ["searchTeamByNameAndTag", searchPagination],
      queryFn: async () => {
        const response = await searchTeamByNameAndTag(searchPagination);

        if (response) {
          return {
            data: response.data,
            page: response.page,
            limit: response.limit,
            total: response.total,
            totalPage: response.totalPage,
          };
        }
        return { data: [], page: 0, limit: 0 };
      },
    });


  const optionsFetchTeamById = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamProfile", teamId],
      queryFn: () => getProfileTeam(teamId!),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamByIdPublic = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamByIdPublic", teamId],
      queryFn: () => getTeamByIdPublic(teamId!),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamMembersByIdPublic = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamMembersByIdPublic", teamId],
      queryFn: () => getTeamMembersByIdPublic(teamId!),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamNotifications = (isAuthenticated: boolean) => {
    return queryOptions({
      queryKey: ["teamNotificarionUser"],
      queryFn: getInviteNotificationsUser,
      enabled: isAuthenticated,
    });
  };

  return {
    optionsFetchGameId,
    optionsFetchGames,
    optionsFetchTeamMembers,
    optionsFetchTeamById,
    optionsFetchTeamNotifications,
    optionsFetchTeamByIdPublic,
    optionsFetchTeamMembersByIdPublic,
    optionsFetchSearchTeamByNameAndTag,
    optionsFetchGamesPublic
  };
};

export const {
  optionsFetchTeamByIdPublic,
  optionsFetchGameId,
  optionsFetchGames,
  optionsFetchTeamMembers,
  optionsFetchTeamById,
  optionsFetchTeamNotifications,
  optionsFetchTeamMembersByIdPublic,
  optionsFetchSearchTeamByNameAndTag,
  optionsFetchGamesPublic
} = optionsQuery();
