import { Genders } from "@interfaces/user.interface";
import * as yup from "yup";

export const RegistrationScheme = yup
  .object({
    name: yup.string().required("Campo obrigatório"),
    nick: yup.string().required("Campo obrigatório"),
    cpf: yup
      .string()
      // .length(11, "CPF deve ter 11 caracteres")
      .required("Campo obrigatório"),
    phone: yup.string().required("Campo obrigatório"),
    email: yup.string().email().required("Campo obrigatório"),
    gender: yup.mixed<Genders>()
      .transform((value) => (value === "" ? null : value))
      .nullable()
      .required("O campo Gênero é obrigatório").oneOf(Object.values(Genders)),
    password: yup
      .string()
      .min(8, "Senha deve ter no mínimo 8 caracteres")
      .required("Campo obrigatório"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[01-9])(?=.*[@$!%*?&])([A-Za-z01-9@$!%*?&#.<>()_+=]{8,})$/,
    //   [
    //     "- Senha deve conter pelo menos 8 caracteres",
    //     "- Incluir uma letra maiúscula",
    //     "- Incluir uma letra minúscula",
    //     "- Incluir um número",
    //     "- Incluir um caractere especial",
    //   ].join("\n")
    // ),
    provider: yup.string().required("Campo obrigatório"),
    passwordConfirm: yup
      .string()
      .required("O campo Confirmar Senha é obrigatório.")
      .oneOf([yup.ref("password"), ""], "As senhas devem ser iguais"),
  })
  .required();

export const ResetPasswordScheme = yup.object({
  email: yup.string().email(),
  token: yup.string(),
  password: yup
    .string()
    .min(8, "Senha deve ter no mínimo 8 caracteres")
    .required("Campo obrigatório"),
  passwordConfirm: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), ""], "As senhas devem ser iguais"),
});

export const LoginSchema = yup
  .object({
    email: yup.string().email().required("Campo obrigatório."),
    password: yup.string().required("Campo obrigatório."),
  })
  .required();

export const ForgotPasswordScheme = yup
  .object({
    email: yup.string().email().required("Campo obrigatório."),
  })
  .required();
