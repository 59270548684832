export const breakpoints = {
  mobileSmall: '320px',
  mobileMedium: '375px',
  mobileLarge: '425px',
  tabletSmall: '600px',
  tablet: '768px',
  tabletLarge: '1024px',
  laptopSmall: '1280px',
  laptop: '1366px',
  laptopLarge: '1440px',
  desktopSmall: '1600px',
  desktop: '1920px',
  desktopLarge: '2560px',
  notebook: '1366px',
  widescreen: '3440px',
  ultraWide: '5120px',
};


export const device = {
  mobileSmall: `(max-width: ${breakpoints.mobileSmall})`,
  mobileMedium: `(max-width: ${breakpoints.mobileMedium})`,
  mobileLarge: `(max-width: ${breakpoints.mobileLarge})`,
  tabletSmall: `(max-width: ${breakpoints.tabletSmall})`,
  tablet: `(max-width: ${breakpoints.tablet})`,
  tabletLarge: `(max-width: ${breakpoints.tabletLarge})`,
  laptopSmall: `(max-width: ${breakpoints.laptopSmall})`,
  laptop: `(max-width: ${breakpoints.laptop})`,
  laptopLarge: `(max-width: ${breakpoints.laptopLarge})`,
  desktopSmall: `(max-width: ${breakpoints.desktopSmall})`,
  desktop: `(max-width: ${breakpoints.desktop})`,
  desktopLarge: `(max-width: ${breakpoints.desktopLarge})`,
  notebook: `(max-width: ${breakpoints.notebook})`,
  widescreen: `(max-width: ${breakpoints.widescreen})`,
  ultraWide: `(max-width: ${breakpoints.ultraWide})`,
};

export const modalGlobalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#102131",
    border: "none",
    padding: "24px",
    maxWidth: "315px",
    width: "100%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "1000",
  },
};

export const statusColors = {
  "EM BREVE": { color: "#3E84EE"},
  "EM ANDAMENTO": { color: "#E6A939" },
  "ENCERRADO": { color: "#E63946"},
};
