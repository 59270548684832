import { IUser } from "./user.interface";

export interface ICreateTeamRequest {
  name: string;
  description: string;
  teamTag: string;
  game_id: string;
}

export interface IUpdateTeamRequest {
  name: string;
  description: string;
  teamTag: string;
}

export interface IInviteMemberRequest {
  userId: string;
  role: TeamMembersRoles;
}

export interface ITeam {
  id: string;
  name: string;
  description: string;
  is_active: boolean;
  organization_id: string | null;
  owner_id: string;
  team_tag: string;
  created_at: string;
  updated_at: string;
  team_members: any[];
  owner: ITeamMemberWoner;
}

export interface ITeamMembers {
  id: string;
  team_id: string;
  user_id: string;
  role: TeamMembersRoles;
  is_captain: false;
  created_at: string;
  updated_at: string;
  user_name: string;
}

export enum TeamMembersRoles {
  OWNER = "OWNER",
  MANAGER = "MANAGER",
  COACH = "COACH",
  PLAYER = "PLAYER",
}

interface ITeamMemberWoner {
  id: string;
  name: string;
}

export interface ITeamNotificationUser {
  id: string;
  team_id: string;
  user_id: string;
  status: TeamNotificationUserStatus;
  created_at: string;
  updated_at: string;
  role: TeamMembersRoles;
  user: Pick<IUser, "id" | "nick" | "email">;
  userWhoInvited: Pick<IUser, "id" | "nick" | "email">;
  team: Pick<ITeam, "id" | "name" | "team_tag">;
}

export enum TeamNotificationUserStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export type TeamUser = Omit<ITeam, "team_members" | "owner">;

export type UpdateTeam = Pick<ITeam, "id" | "name" | "description"> & {
  teamTag: string;
};
