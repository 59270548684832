import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bell } from "lucide-react";
import NotificationArea from "./components/notificationArea";
import * as S from "./styles";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import useUser from "@hooks/useUser";
import { IoIosArrowDown } from "react-icons/io";
import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import useAuth from "@hooks/useAuth";
import useNotification from "@hooks/useNotification";

const HeaderRight: React.FC = () => {
  const navidate = useNavigate();
  const { userAuth, avatarFile } = useUser();
  const { logout } = useAuth();
  const [dropdownActive, setDropdownActive] = useState(false);
  const { numberOpenNotifications } = useNotification();

  function toggleDropdownActivation(event) {
    event.stopPropagation();
    setDropdownActive(!dropdownActive);
  }

  function handleDropdownMouseLeave() {
    setDropdownActive(true);
  }

  return (
    <S.RightContainer>
      <S.ContainerButtonWrapper>
        {/* <S.ContainerLinkAssinar to="">Assinar</S.ContainerLinkAssinar> */}
        <AuthenticatedUserDisplay.True>
          <S.ContainerUserWrapper>
            <Flex cursor={"pointer"}>
              {numberOpenNotifications > 0 && (
                <Flex
                  w={"20px"}
                  h={"20px"}
                  bg={"#e94560"}
                  borderRadius={"100%"}
                  position={"absolute"}
                  left={"16px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  top={"-1px"}
                >
                  {numberOpenNotifications}
                </Flex>
              )}
              <Bell size={24} onClick={toggleDropdownActivation} />
            </Flex>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<IoIosArrowDown color="#E94560" />}
                bg="none"
                _hover={{ background: "none" }}
                _active={{ background: "none" }}
              >
                <Avatar
                  src={
                    avatarFile?.url
                      ? avatarFile?.url
                      : "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
                  }
                  size="md"
                />
              </MenuButton>
              <MenuList bg="#0D2134" borderColor="#E94560">
                <MenuItem
                  bg="#0D2134"
                  onClick={() => navidate(`/profile/${userAuth?.id}`)}
                >
                  Perfil
                </MenuItem>
                <MenuItem bg="#0D2134" onClick={logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
            {dropdownActive && (
              <NotificationArea onMouseLeave={handleDropdownMouseLeave} />
            )}
          </S.ContainerUserWrapper>
        </AuthenticatedUserDisplay.True>
        <AuthenticatedUserDisplay.False>
          <Link to="/login">
            <S.ContainerLinkEntrar>Entrar</S.ContainerLinkEntrar>
          </Link>
        </AuthenticatedUserDisplay.False>
        {/* <S.ContainerMobileMenuWrapper>
          <Sidebar
            setSidebarIsActive={setSidebarIsActive}
            sidebarIsActive={sidebarIsActive}
          />
          <button onClick={showSidebar}>
            <AlignJustify />
          </button>
        </S.ContainerMobileMenuWrapper> */}
      </S.ContainerButtonWrapper>
    </S.RightContainer>
  );
};

export default HeaderRight;
