import styled from "styled-components";
import { device } from "@utils/index.ts";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ bgColor }) => bgColor || "#FFF"};
    color: ${({ textColor }) => textColor || "#FFF"};

    border-radius: 8px;
    border-left: 15px solid ${({ borderColor }) => borderColor};
    border-top: 1px solid ${({ borderColor }) => borderColor};
    border-bottom: 1px solid ${({ borderColor }) => borderColor};
    @media ${device.tabletSmall} {
        border-left: 0;
        border: 1px solid ${({ borderColor }) => borderColor || "#FFF"};

        flex-direction: column;
    }


`;

export const DateSection = styled.div`
    width: 200px;
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    height: 100%;

    background-color: ${({ borderColor }) => borderColor || "#FFF"};

    .month {
        color: ${({ textColor }) => textColor || "#FFF"};
        font-weight: bold;
        font-size: 1rem;
    }

    @media ${device.tablet} {
        .day {
            font-size: 2rem;
        }
    }

    @media ${device.tabletSmall} {
        margin-bottom: 1rem;
    }

    @media ${device.laptop} {
        .month { 
            font-size: 1rem;
        }
    }

    @media ${device.tabletSmall} {
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; 
    }
`;

export const Section = styled.div`
    width: 100%; 
    font-size: 1.2rem;
    word-wrap: break-word;  
    margin-left: 4rem;
    @media ${device.tablet} {
        margin-left: 5%;
    }

    @media ${device.notebook} {
        margin-left: 10%;
    }
`;

export const Day = styled.div`
  font-size: 4rem;
  font-weight: bold;
  color: ${({ textColor }) => textColor || "#FFF"};

  @media ${device.tablet} {
    font-size: 2rem;
  }

  @media ${device.tabletSmall} {
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled.div`
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
`;

export const Time = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  width: 100%;

  @media ${device.tablet} {
    font-size: 0.8rem;
  }
  @media ${device.notebook} {
    font-size: 1rem;
  }
  @media ${device.tabletSmall} {
    margin-bottom: 2rem;
  }
`;
