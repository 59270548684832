import TeamProfile from "./components/teamProfile";
import Members from "./components/members";
import * as S from "./styles";
import * as GS from "@components/template/global/style";
import Layout from "@components/template/layout";
import { useParams } from "react-router-dom";
import { Settings } from "lucide-react";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import ImageHover from "@components/imageHover";
import ImageCropper from "@components/imageCropper";
import { FileBussinessType, FileEntityType } from "@interfaces/file.interface";
import Modal from "@components/template/modal";
import useUser from "@hooks/useUser";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import useAuth from "@hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUpdateTeamRequest, UpdateTeam } from "@interfaces/team.interface";
import { UpdateTeamSchema } from "@validations/teams.schema";
import { SubmitHandler, useForm } from "react-hook-form";
import useTeamPublic from "@hooks/useTeamPublic";
import useTeam from "@hooks/useTeam";
import { useQueryClient } from "@tanstack/react-query";
import { optionsFetchTeamByIdPublic } from "@plugins/reactQuery/queryOptions.reactquery";
import { Helmet } from "react-helmet-async";

const Team: React.FC = () => {
  const queryClient = useQueryClient();
  const { teamId } = useParams();
  const modalUpdateBanner = useDisclosure();
  const modalUpdatePhoto = useDisclosure();
  const modalUpdateProfile = useDisclosure();
  const { team, bannerFile, avatarFile } = useTeamPublic(teamId);
  const { updateTeam } = useTeam();
  const { isAuthenticated } = useAuth();
  const { userAuth } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<UpdateTeam>({
    resolver: yupResolver(UpdateTeamSchema),
    values: {
      id: team?.id!,
      name: team?.name!,
      teamTag: team?.team_tag!,
      description: team?.description!,
    },
  });

  const submitHandler: SubmitHandler<IUpdateTeamRequest> = async (
    values,
    event
  ) => {
    event?.preventDefault();
    const data: IUpdateTeamRequest = {
      teamTag: values.teamTag,
      name: values.name,
      description: values.description,
    };
    await updateTeam(values.id, data);
    modalUpdateProfile.onClose();
  };

  const closeModal = () => {
    modalUpdateBanner.onClose();
    modalUpdatePhoto.onClose();
    queryClient.invalidateQueries(optionsFetchTeamByIdPublic(teamId!));
  };

  return (
    <>
      <Helmet>
        <title>Time - Progamers</title>
        <meta property="og:title" content="Time - Progamers" />
      </Helmet> 
      <Layout>
        <ImageHover
          hoverText="Atualizar Banner"
          altImage="team_banner"
          handlerClick={modalUpdateBanner.onOpen}
          imgPath={bannerFile?.url!}
          isEdit={isAuthenticated && team?.owner.id === userAuth?.id}
          styleContainer={{
            width: "99vw",
            height: "430px",
            marginBottom: "-6rem",
          }}
        />
        <GS.TabsContainer>
          <S.TeamAvatarWrapper>
            <S.TeamInformationWrapper>
              <ImageHover
                imgPath={avatarFile?.url!}
                hoverText="Atualizar Perfil"
                altImage="team_avatar"
                handlerClick={modalUpdatePhoto.onOpen}
                isEdit={isAuthenticated && team?.owner.id === userAuth?.id}
                styleContainer={{
                  width: "240px",
                  height: "240px",
                  borderRadius: "250px",
                }}
              />
              <S.TeamAvatarTextWrapper>
                <S.TeamStatus>TIME</S.TeamStatus>
                <S.TeamName>{team?.name}</S.TeamName>
                <S.TeamStatus>{team?.team_members.length} Membros</S.TeamStatus>
              </S.TeamAvatarTextWrapper>
            </S.TeamInformationWrapper>
          </S.TeamAvatarWrapper>
          <Tabs w={"100%"} colorScheme={"#FFFFFF"}>
            <TabList borderBottomColor={"#E94560"}>
              <Tab>GERAL</Tab>
              <Tab>MEMBROS</Tab>
              <AuthenticatedUserDisplay.True
                rule={team?.owner.id === userAuth?.id}
              >
                <Button
                  onClick={modalUpdateProfile.onOpen}
                  variant="outline"
                  gap={"5px"}
                  border={"1px solid var(--layout-color)"}
                  borderRadius={"8px"}
                  color={"#ffffff"}
                  p={"0.5rem 0.8rem"}
                  transition={"all ease-in-out 0.3s"}
                  position={"absolute"}
                  right={"1px"}
                  top={"-8px"}
                  _hover={{
                    backgroundColor: "var(--layout-color)",
                    transition: "all ease-in 0.2s",
                  }}
                >
                  <Settings size={22} />
                  <p>Configurações</p>
                </Button>
              </AuthenticatedUserDisplay.True>
            </TabList>
            <TabPanels>
              <TabPanel>
                <TeamProfile />
              </TabPanel>
              <TabPanel>
                <Members />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GS.TabsContainer>
      </Layout>
      <Modal
        title="Foto de Perfil"
        isOpen={modalUpdatePhoto.isOpen}
        handlerCancel={modalUpdatePhoto.onClose}
        isFooter={false}
      >
        <ImageCropper
          handlerCompleted={closeModal}
          crop={{ height: 270, width: 270, isCircularCrop: true }}
          file={{
            id: avatarFile?.id,
            url: avatarFile?.url!,
            entity_id: team?.id!,
            business_type: FileBussinessType.TEAM_PROFILE_PHOTO,
            entity_type: FileEntityType.TEAM,
          }}
        />
      </Modal>
      <Modal
        title="Foto de Banner"
        isOpen={modalUpdateBanner.isOpen}
        handlerCancel={modalUpdateBanner.onClose}
        isFooter={false}
        size="6xl"
      >
        <ImageCropper
          isBanner
          handlerCompleted={closeModal}
          crop={{ height: 270, width: 1920, isCircularCrop: false }}
          file={{
            id: bannerFile?.id,
            url: bannerFile?.url!,
            entity_id: team?.id!,
            business_type: FileBussinessType.TEAM_BANNER_PHOTO,
            entity_type: FileEntityType.TEAM,
          }}
        />
      </Modal>
      <Modal
        title="Atualizar Time"
        isOpen={modalUpdateProfile.isOpen}
        handlerCancel={modalUpdateProfile.onClose}
        isFooter={false}
      >
        <S.FormContainer onSubmit={handleSubmit(submitHandler)}>
          <S.FormWrapper>
            <S.FormLabel htmlFor="profileName">Name</S.FormLabel>
            <S.FormInput
              id="profileName"
              type="text"
              placeholder="Nome do Time"
              {...register("name")}
            />
            {isSubmitted && errors.name && (
              <S.FormError>{errors.name.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="profileTag">Tag</S.FormLabel>
            <S.FormInput
              id="profileTag"
              type="text"
              placeholder="Tag do Time"
              {...register("teamTag")}
            />
            {isSubmitted && errors.teamTag && (
              <S.FormError>{errors.teamTag.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="profileDescription">Description</S.FormLabel>
            <S.FormTextarea
              rows={3}
              cols={50}
              placeholder="Escreva uma breve descrição para o seu time"
              {...register("description")}
              maxLength={200}
              id="profileDescription"
              {...register("description")}
            />
            {isSubmitted && errors.description && (
              <S.FormError>{errors.description.message}</S.FormError>
            )}
          </S.FormWrapper>
          <S.PrimaryAction type="submit">Salvar</S.PrimaryAction>
        </S.FormContainer>
      </Modal>
    </>
  );
};

export default Team;
