import styled from "styled-components";
import { device } from "@utils/index.ts";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  @media ${device.mobileMedium} {
    grid-gap: 8px;
  }
`;

export const GroupInfor = styled.div`
  display: flex;
  gap: 1.3rem;
  flex-direction: row;
  width: 100%;
  @media ${device.tabletSmall} {
    flex-direction: column;
  }
`;

export const InfoBox = styled.div`
    background: ${({ bgColor }) =>
            `linear-gradient(267deg, ${bgColor} 0%, rgba(255,0,125,0) 100%)`};
    gap: 1.5rem;
    color: ${({ textColor }) => textColor || "#FFF"};
    max-width: fit-content;
    padding: 10px;
    text-align: center;

    display: flex;
    min-width: 155px;
    width: 100%;
    font-size: 1.2rem;
    border-radius: 12px;

    .map_url {
        background: ${({ bgColor }) => bgColor};
        border-radius: 8px;
    }

    @media ${device.laptop} {

        font-size: 1rem;
        span {
            margin-top: 0.2rem;s
        }
    }

    @media ${device.tabletSmall} {
        font-size: 0.8rem;
    }
    @media ${device.mobileMedium} {
        min-width: 100%;
    }
    @media ${device.mobileLarge} {
        min-width: 100%;
    }
`;

export const ButtonMap = styled.button`
  display: flex;
  gap: 10px;
  padding: 0.8rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${({ bgColor }) => bgColor || "#FFF"};
  color: ${({ textColor }) => textColor || "#FFF"};
  width: 100%;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 20px;
`;

// COUNTER TIMER
export const ContainerTimer = styled.div`
    display: flex;
    margin-top: 4rem;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: white;

    @media ${device.mobileMedium} {
        gap: 8px;
    }
`;

export const TimeBox = styled.div`
  padding: 16px;
  width: 200px;
  text-align: center;
  border-radius: 12px;
  border: 2px solid ${({ borderColor }) => borderColor || "#FFF"};

  @media ${device.tabletSmall} {
    width: 100px;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    width: 100px;
  }

  @media ${device.mobileMedium} {
    font-size: 1rem;
    padding: 8px;
    width: 80px;
  }

  @media ${device.mobileLarge} {
    font-size: 1rem;
    padding: 8px;
    width: 80px;
  }

  @media ${device.tablet} {
    font-size: 1rem;
    padding: 8px;
    width: 80px;
  }
`;

export const TimeValue = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${({ textColor }) => textColor || "#FFF"};

  @media ${device.tabletSmall} {
    font-size: 2rem;
  }

  @media ${device.laptop} {
    font-size: 2rem;
  }

  @media ${device.mobileMedium} {
    font-size: 1rem;
  }
  @media ${device.mobileLarge} {
    font-size: 1rem;
  }

  @media ${device.tablet} {
    font-size: 1.5rem;
  }
`;

export const TimeLabel = styled.div`
  font-size: 1.5rem;
  color: ${({ textColor }) => textColor || "#FFF"};

  @media ${device.tabletSmall} {
    font-size: 0.8rem;
  }

  @media ${device.laptop} {
    font-size: 0.8rem;
  }

  @media ${device.mobileMedium} {
    font-size: 0.5rem;
  }

  @media ${device.mobileLarge} {
    font-size: 0.5rem;
  }
  @media ${device.tablet} {
    font-size: 0.5rem;
    font-size: 0.5rem;
  }
`;
export const ButtonShare = styled.button`
  display: flex;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor || "#E63946"};
  width: 60px;
  height: 60px;
  border-radius: 100%;
`;
