import Layout from "@components/template/layout";
import React, { useEffect } from "react";
import {
  Container,
  Counter,
  ListCards,
  ListSchedule,
  Section,
  SectionChampionship,
  SectionTitle,
} from "@pages/event/styles.ts";
import ScheduleItem from "@components/scheduleItem/ScheduleItem.tsx";
import { useParams } from "react-router-dom";
import useEvent from "@hooks/useEvent.ts";
import ChampionshipsEventSession from "@pages/homePage/components/championshipsEvent";
import Countdown from "@components/eventDetails/CountEvent.tsx";
import SectionCard from "@components/SectionCard";
import Spinner from "@components/template/spinner";
import EventNotFound from "@pages/event/EventNotFound";
import { motion } from "framer-motion";
import { FileBussinessType } from "@interfaces/file.interface.ts";
import { Box, Flex, Image as ImageChakra, Link } from "@chakra-ui/react";
import { FaLocationDot } from "react-icons/fa6";
import DOMPurify from "dompurify";
import imageEvent from "@assets/ibyte/papapapap.jpg";
import { findEarliestDateTime } from "@utils/date";
import { Helmet } from "react-helmet-async";

const EventPage: React.FC = () => {
  const { category, eventName } = useParams();
  const url = `${category}/${eventName}`;
  const { getEventByURL } = useEvent();

  let primary = "red";
  let secondary = "red";
  let pageColor = "#008B8B";
  let textColor = "#008B8B";
  let socialLink = [];
  let headline = "";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { data, isLoading } = getEventByURL(url!);

  const configPage = data?.entity?.event_page_entity;
  const event = data?.entity;
  const sanitizedContent = DOMPurify.sanitize(event?.description);

  primary = configPage?.page_config?.primary;
  secondary = configPage?.page_config?.secondary;
  pageColor = configPage?.page_config?.pageColor;
  textColor = configPage?.page_config?.textColor;
  socialLink = configPage?.page_config?.socialLink;
  headline = configPage?.page_config?.headline;

  const fileBanner = data?.files?.find(
    (file) => file.business_type === FileBussinessType.EVENT_BANNER
  );

  const formatarIntervaloDatas = (startDate: string, endDate: string) => {
    const mesesAno = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    const dates = [];

    const mes = mesesAno[newStartDate.getMonth()];

    while (newStartDate <= newEndDate) {
      const dia = newStartDate.getDate();
      dates.push(`${dia}`);
      newStartDate.setDate(newStartDate.getDate() + 1);
    }

    let result = "";

    if (dates.length == 1) {
      result = `${dates[0]} ${mes}`;
    }

    if (dates.length == 2) {
      result = `${dates[0]} e ${dates[1]} ${mes}`;
    }

    if (dates.length > 2) {
      const ultimo = dates.pop();
      result = `${dates.join(", ")} e ${ultimo} ${mes}`;
    }

    return result;
  };

  const formatHour = (dateHour: string) => {
    const hour = dateHour.split("T")?.[1]?.split(":");
    return `${hour[0]}:${hour[1]}`;
  };

  const dateStartEvent= findEarliestDateTime(data?.entity?.event_schedule);

  return (
    <>
      <Helmet>
        <title>Evento - Progamers</title>
        <meta property="og:title" content="Eventos - Progamers" />      
      </Helmet> 
      
      <Layout
      primary={primary}
      secondary={secondary}
      showSidebar={false}
      styleContainer={{ overflow: "visible" }}
      styleBodyContainer={{ overflow: "visible" }}
    >
      {isLoading && <Spinner />}
      {!isLoading && data === undefined && <EventNotFound />}
      {!isLoading && data !== undefined && (
        <Container bgColor={pageColor} style={{ height: "100%" }}>
          <ImageChakra src={fileBanner?.url} objectFit={"cover"} w={"100%"} />

          <Counter bgColor={primary}>
            <Countdown
              textColor={textColor}
              primary={secondary}
              targetDate={dateStartEvent}
            />
          </Counter>
          <Flex
            mt={"40px"}
            color={"#ffffff"}
            flexDir={{ base: "column", lg: "row" }}
            justifyContent={{ base: "center", lg: "space-evenly" }}
            p={{ base: "12px", lg: "0px" }}
            gap={{ base: "25px" }}
            alignItems={"center"}
          >
            <Flex
              flexDir={"column"}
              w={{ base: "100%", lg: "45%" }}
              gap={"12px"}
              justifyContent={"center"}
              fontSize={"1.2rem"}
            >
              <Flex
                gap={"8px"}
                flexDir={{ base: "column", lg: "row", md: "row" }}
              >
                <Flex
                  borderRadius={"8px"}
                  bgColor={primary}
                  w={"auto"}
                  p={"10px 12px"}
                  gap={"8px"}
                >
                  <span>LOCAL:</span>
                  <span>{event?.location}</span>
                </Flex>
                <Flex
                  borderRadius={"8px"}
                  bgColor={primary}
                  w={"auto"}
                  p={"10px 12px"}
                  gap={"8px"}
                >
                  <span>EVENTO:</span>
                  <span>
                    {event?.event_type === "FREE" ? "GRATIS" : "PAGO"}
                  </span>
                </Flex>
              </Flex>
              <Flex
                gap={"8px"}
                flexDir={{ base: "column", lg: "row", md: "row" }}
              >
                <Flex
                  borderRadius={"8px"}
                  bgColor={primary}
                  p={"5px 12px"}
                  flexDir={"column"}
                  alignItems={"start"}
                >
                  <Flex gap={"8px"}>
                    <span>DIAS:</span>
                    {formatarIntervaloDatas(event?.start_date, event?.end_date)}
                  </Flex>
                  <Flex gap={"8px"}>
                    <span>HORÁRIO:</span>
                    <p>{formatHour(event?.start_time)}</p>
                  </Flex>
                </Flex>

                <Flex
                  borderRadius={"8px"}
                  bgColor={primary}
                  p={"8px"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"8px"}
                >
                  PRESENCIAL
                </Flex>

                <Link
                  borderRadius={"8px"}
                  bgColor={primary}
                  p={"10px 12px"}
                  href={event?.map_url}
                  target="_blank"
                  display={"flex"}
                  alignItems={"center"}
                  gap={"8px"}
                  _hover={{ textDecoration: "none" }}
                >
                  <FaLocationDot />
                  Rota
                </Link>
              </Flex>
              <Flex
                borderRadius={"8px"}
                bgColor={primary}
                gap={"8px"}
                flexDir={"column"}
                textAlign={"justify"}
                p={"10px 12px"}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
              ></Flex>
            </Flex>
            <Box position="relative" display="inline-block" p={"6px"}>
              <ImageChakra src={imageEvent} borderRadius={"6px"} />
              <Box
                position="absolute"
                top="-5px"
                left="-5px"
                width="20px"
                height="20px"
                borderLeft="5px solid #ffffff"
                borderTop="5px solid #ffffff"
                borderTopLeftRadius={"8px"}
              />
              <Box
                position="absolute"
                top="-5px"
                right="-5px"
                width="20px"
                height="20px"
                borderRight="5px solid #ffffff"
                borderTop="5px solid #ffffff"
                borderTopRightRadius={"8px"}
              />
              <Box
                position="absolute"
                bottom="-5px"
                left="-5px"
                width="20px"
                height="20px"
                borderLeft="5px solid #ffffff"
                borderBottom="5px solid #ffffff"
                borderBottomLeftRadius={"8px"}
              />
              <Box
                position="absolute"
                bottom="-5px"
                right="-5px"
                width="20px"
                height="20px"
                borderRight="5px solid #ffffff"
                borderBottom="5px solid #ffffff"
                borderBottomRightRadius={"8px"}
              />
              <Box
                position="absolute"
                top="-5px"
                left="47%"
                right="47%"
                height="5px"
                bg="#ffffff"
              />
              <Box
                position="absolute"
                bottom="-5px"
                left="47%"
                right="47%"
                height="5px"
                bg="#ffffff"
              />
            </Box>
          </Flex>
          {data?.entity?.event_championships_entity.length > 0 && (
            <SectionChampionship>
              <SectionTitle textColor={textColor} bgColor={primary}>
                Competições disponíveis
              </SectionTitle>
              <ChampionshipsEventSession event={data} titleSection={""} />
            </SectionChampionship>
          )}
          {data?.entity.has_attraction && (
            <Section>
              <SectionTitle textColor={textColor} bgColor={primary}>
                Atrações
              </SectionTitle>
              <ListCards className="relative">
                {data?.entity?.events_attractions_entity?.map(
                  (item: any, index: number) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0, y: 40 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: false, amount: 0.3 }} // Ativa quando 30% do elemento está visível
                      transition={{ duration: 0.6, delay: index * 0.2 }}
                      className="bg-white p-4 rounded-xl shadow-md"
                    >
                      <SectionCard
                        primary={primary}
                        secondary={secondary}
                        textColor={textColor}
                        index={index}
                        name={item?.entity?.name}
                        description={item?.entity?.description}
                        backgroundImage={
                          item?.files?.[0]?.url || "/placeholder.jpg"
                        }
                      />
                    </motion.div>
                  )
                )}
              </ListCards>
            </Section>
          )}
          {data?.entity.has_arena && (
            <Section>
              <SectionTitle textColor={textColor} bgColor={primary}>
                Arenas
              </SectionTitle>
              <ListCards>
                {data?.entity?.events_arenas_entity?.map(
                  (item: any, index: number) => {
                    return (
                      <motion.div
                        key={item.id}
                        initial={{ opacity: 0, y: 40 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: false, amount: 0.3 }}
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                        className="bg-white p-4 rounded-xl shadow-md"
                      >
                        <SectionCard
                          primary={primary}
                          secondary={secondary}
                          textColor={textColor}
                          key={item.id}
                          index={index}
                          name={item?.entity?.name}
                          description={item?.entity?.description}
                          backgroundImage={item?.files[0]?.url}
                        />
                      </motion.div>
                    );
                  }
                )}
              </ListCards>
            </Section>
          )}
          {data?.entity?.event_schedule.length > 0 && (
            <Section>
              <SectionTitle textColor={textColor} bgColor={primary}>
                Cronograma do evento
              </SectionTitle>
              <ListSchedule>
                {data?.entity?.event_schedule
                  ?.sort((a: any, b: any) => {
                    const dateA = new Date(
                      `${a.date.split("T")[0]}T${a.start_time}:00`
                    );
                    const dateB = new Date(
                      `${b.date.split("T")[0]}T${b.start_time}:00`
                    );

                    return dateA.getTime() - dateB.getTime();
                  })
                  .map((item: any, index: number) => {
                    return (
                      <motion.div
                        key={item.id}
                        initial={{ opacity: 0, y: 40 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: false, amount: 0.3 }}
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                        className="bg-white p-4 rounded-xl shadow-md"
                      >
                        <ScheduleItem
                          primary={primary}
                          secondary={secondary}
                          textColor={textColor}
                          schedule={item}
                          key={item.id}
                        />
                      </motion.div>
                    );
                  })}
              </ListSchedule>
            </Section>
          )}
        </Container>
      )}
    </Layout>
    
    </>
 
  );
};

export default EventPage;
