import styled from "styled-components";
import { Avatar, ListItem as ItemList } from "@chakra-ui/react";
import { device } from "@utils/index.ts";

export const ListItem = styled(ItemList)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  margin: 10px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #0a1428;
  border: 1px solid transparent;
  opacity: ${(props) => (props.isSelected ? 0.5 : 1)};
  color: ${(props) =>
    props.isSelected ? "var(--text-greay)" : "var(--white)"};

  &:hover {
    border: 1px solid
      ${(props) =>
        props.isSelected
          ? "var(--pill-background-color)"
          : "var(--layout-color)"};
  }
`;

export const AvatarProfile = styled(Avatar)`

      @media ${device.mobileLarge} { 
            font-size: 12px;
      }
`;

export const PlayerInfo = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      @media ${device.mobileLarge} {
            font-size: 12px;
      }
`;




export const Send = styled.span`
  margin-left: auto;
`;

export const SearchInput = styled.div`
      display: flex;
      width: 100%;
      align-items: center;
      background-color: var(--input-filter-color);
      margin-bottom: 1rem;
      border-radius: 8px;
      padding: 10px;

      @media ${device.tablet} {
            width: fit-content;
            font-size: 12px;
      }
 
`;

export const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  color: white;
  width: 100%;

  @media ${device.tablet} {
    font-size: 12px;
    width: 180px;
  }

  @media ${device.mobileLarge} {
    font-size: 12px;
  }
`;
