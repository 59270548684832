import * as S from "./style";
import Autocomplete from "@components/autocompleteInput";
import useTeam from "@hooks/useTeam";
import Modal from "@components/template/modal";

type Props = {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
};

const InviteMemberModal: React.FC<Props> = ({ isOpen, onClose, teamId }) => {
  const { invite, searchUsersTeamInvitation } = useTeam(teamId);

  return (
    <Modal
      isOpen={isOpen}
      handlerCancel={onClose}
      title="Convidar Membros"
      bgColor="#102131"
      isFooter={false}
    >
      <S.ModalContainer>
        <Autocomplete suggestions={searchUsersTeamInvitation} select={invite} />
      </S.ModalContainer>
    </Modal>
  );
};

export default InviteMemberModal;
